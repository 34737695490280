import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Typography, CircularProgress } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { MainButton } from '@/components/UI'
import { FlintField } from '@/components/UI/FlintForm'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@flint/graphql'
import { ADD_IP_ADDRESS, UPDATE_IP_ADDRESS } from '@/graphql'
import { useQuota } from '@/hooks'
import { createIPAddressValidator } from '@/validators'
import { useStyle } from './IPAddressesPageAdd.style'
import {
  IPAddressesPageAddProps,
  ipFormInitialValuesType,
} from './IPAddressesPageAdd.interface'

export const IPAddressesPageAdd: FC<IPAddressesPageAddProps> = ({
  onSuccessCreate,
  ipFormInitialValues,
  setIpFormInitialValues,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const {
    quota: {
      organization: { id: orgId },
    },
  } = useQuota()

  const [AddIPAddress] = useMutation(ADD_IP_ADDRESS)
  const [UpdateIpAddress] = useMutation(UPDATE_IP_ADDRESS)
  const [loadForm, setLoadForm] = useState(true)
  const onSubmit = async (
    values: ipFormInitialValuesType,
    { setSubmitting, resetForm }: FormikHelpers<ipFormInitialValuesType>
  ) => {
    if (values.id) {
      try {
        UpdateIpAddress({
          variables: {
            orgId,
            ip: values.ip,
            notes: values.notes,
            id: values.id,
          },
        })
      } finally {
        setIpFormInitialValues({
          id: '',
          ip: '',
          notes: '',
        })
        onSuccessCreate()
        setSubmitting(false)
      }
    } else {
      try {
        AddIPAddress({
          variables: {
            orgId,
            ip: values.ip,
            notes: values.notes,
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        resetForm()
        onSuccessCreate()
        setSubmitting(false)
      }
    }
  }
  useEffect(() => {
    setLoadForm(false)
    setTimeout(() => {
      setLoadForm(true)
    }, 500)
  }, [ipFormInitialValues.id])
  const onClickCancelButton = (resetForm) => {
    if (ipFormInitialValues.id) {
      setIpFormInitialValues({
        id: '',
        ip: '',
        notes: '',
      })
    } else {
      resetForm()
    }
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.addNewIPTitle}>
        {t('add new ip address')}
      </Typography>

      {loadForm ? (
        <div>
          <Formik
            initialValues={ipFormInitialValues}
            validationSchema={createIPAddressValidator}
            onSubmit={onSubmit}
          >
            {({ resetForm, isSubmitting }) => (
              <Form>
                <FlintField
                  name="ip"
                  style={{ marginBottom: 5 }}
                  label={t('enter new ip address')}
                  placeholder={t('you can add IP or CIDR')}
                />

                <Typography
                  style={{ marginBottom: 20 }}
                  className={classes.IPAddressExample}
                >
                  {t('ip address example')}
                </Typography>

                <FlintField
                  name="notes"
                  label={t('optional notes')}
                  placeholder={t('write notes')}
                />

                <div className={classes.actionsContainer}>
                  <MainButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    size="large"
                    className={classes.actionButton}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} style={{ color: '#fff' }} />
                    ) : (
                      t('save')
                    )}
                  </MainButton>

                  <MainButton
                    disabled={isSubmitting}
                    onClick={() => onClickCancelButton(resetForm)}
                    className={clsx(classes.actionButton, classes.cancelButton)}
                    variant="contained"
                  >
                    {t('cancel')}
                  </MainButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className={classes.formLoaderContainer}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
