import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    '& ::placeholder': {
      fontSize: 13,
      fontFamily: 'Almarai, sans-serif !important',
    },
    '& :-ms-input-placeholder': {
      fontSize: 13,
      fontFamily: 'Almarai, sans-serif !important',
    },
    '& ::-ms-input-placeholder': {
      fontSize: 13,
      fontFamily: 'Almarai, sans-serif !important',
    },
  },
  listTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
  },
}))
