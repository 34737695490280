import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  pageTitle: {
    fontSize: 25,
    color: '#000',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  tableContainer: {
    borderRadius: 10,
    overflow: 'hidden',
    overflowX: 'auto',
    position: 'relative',
    border: '1px solid #dfdfdf',
    '& tbody tr:last-child > *': {
      borderBottom: 'none',
    },
  },
  progressContainer: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  pagination: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))
