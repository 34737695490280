import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useOrg from '@/hooks/useOrg'
import { setOrgState } from '@/redux/org'
import { Box, Dialog, Grid, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { fetchClientAsync } from '@/redux'
import { SwapIcon } from '@/icons'
import { useStyle } from './ClientInfo.style'

export const ClientInfo = () => {
  const classes = useStyle()
  const [dialog, setDialog] = useState(false)
  const { organizations, activeOrg } = useOrg()
  const dispatch = useDispatch()
  const toggleDialog = () => {
    setDialog(!dialog)
  }
  useEffect(() => {
    if (activeOrg.id) dispatch(fetchClientAsync(activeOrg.id))
  }, [activeOrg])
  const onChangeOrg = (org: any) => {
    toggleDialog()
    if (org.id === activeOrg.id) return
    localStorage.setItem('orgId', org.id)
    dispatch(setOrgState('activeOrg', org))
  }
  if (!activeOrg) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={classes.imgWrapper}>
        {activeOrg.fullLogo && (
          <img
            className={classes.logo}
            src={activeOrg.fullLogo}
            alt={`شعار ${activeOrg.name}`}
          />
        )}
        {!activeOrg.fullLogo && (
          <Typography className={classes.clientName}>
            {activeOrg.name}
          </Typography>
        )}
        <IconButton onClick={toggleDialog} className={classes.swapButton}>
          <SwapIcon />
        </IconButton>
      </div>

      <Dialog
        open={dialog}
        onClose={toggleDialog}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>اختر المنشأة</Typography>
          <IconButton onClick={toggleDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {organizations?.map((org: any) => (
            <Grid item md={4} key={org.id} style={{ display: 'flex' }}>
              <button
                type="button"
                className={clsx(classes.orgItem, {
                  [classes.activeOrg]: activeOrg.id === org.id,
                })}
                onClick={() => onChangeOrg(org)}
              >
                {org.fullLogo && (
                  <img
                    src={org.fullLogo}
                    alt={org.name}
                    className={classes.orgLogo}
                  />
                )}
                <Typography
                  className={classes.orgName}
                  style={{ margin: org.fullLogo ? 0 : 'auto' }}
                >
                  {org.name}
                </Typography>
              </button>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </div>
  )
}
