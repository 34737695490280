import React, { FC } from 'react'
import { ReactComponent as NotInvitedBanner } from '@/assets/images/not-invited.svg'
import { useTranslation } from '@flint/locales'
import { useStyle } from './QueriesNotInvited.style'

export const QueriesNotInvited: FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <div className={classes.root}>
      <div className={classes.notInvitedContainer}>
        <div className={classes.notInvitedBanner}>
          <NotInvitedBanner />
        </div>

        <p className={classes.notInvitedTitle}>{t('quota.notInvited')}</p>

        <a href="email:contact@gt.com.sa" className={classes.supportEmail}>
          contact@gt.com.sa
        </a>

        <p className={classes.notInvitedSubTitle}>
          {t('quota.notInvitedSupport')}
        </p>
      </div>
    </div>
  )
}
