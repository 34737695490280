import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid #DFDFDF',
    borderRadius: '8px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25, 0.25, 0.25, 2),
  },
  servicesContainer: {
    borderTop: '1px solid #e7e7e7',
    padding: theme.spacing(1, 2),
  },
  description: {
    fontSize: 12,
    color: '#5c5c5c',
    paddingLeft: theme.spacing(4),
  },
  serviceContainer: {
    marginBottom: theme.spacing(1),
  },
}))
