import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    minHeight: theme.spacing(5),
    border: '1px solid #005F96',
  },
  grouped: {
    border: 'none',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Almarai',
    color: '#005F96',
    borderRadius: '10px!important',
  },
  small: {
    fontSize: 12,
  },
  active: {
    borderRadius: '10px!important',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
}))
