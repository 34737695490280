import { Dispatch } from 'redux'
import { IOrganization } from '@/types'
import { graphQlQuery } from '@flint/graphql'
import { FETCH_ORG } from '@/graphql'
import { setOrgState } from './org.actions'

export const fetchUserOrgs = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setOrgState('loadingOrgs', true))
    try {
      const { data } = await graphQlQuery({
        query: FETCH_ORG as any,
      })
      const { organizations } = data
      // save orgs
      dispatch(setOrgState('orgs', organizations))
      // get first org in the array
      let activeOrg = organizations[0] as IOrganization
      // get saved org id
      const orgId = localStorage.getItem('orgId')
      if (orgId) {
        const org = organizations.find((o: any) => o.id === orgId)
        if (org) {
          activeOrg = org
        }
      }
      // fetch acitve record
      dispatch(setOrgState('activeOrg', activeOrg))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      dispatch(setOrgState('loadingOrgs', false))
    }
  }
}
