import React from 'react'
import { DashboardPage as Dashboard } from '@/modules/DashoardPage'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const DashboardPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('dashboard')}
      pageSubTitle={t('subscriber details and statistics')}
    >
      <Dashboard />
    </Layout>
  )
}
