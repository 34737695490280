import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { PieChart } from '@/components/charts'
import Highcharts from 'highcharts'
import { useTranslation } from '@flint/locales'
import { useStyle } from './DashoardPageStatChart.style'
import { DashoardPageStatChartProps } from './DashoardPageStatChart.interface'

export const DashoardPageStatChart: FC<DashoardPageStatChartProps> = ({
  data,
  name,
  title,
  subTitle,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()
  let total = 0
  data.forEach((d) => {
    total += d.value
  })

  const series: Highcharts.SeriesOptionsType[] = [
    {
      type: 'pie',
      name,
      innerSize: '70%',
      data: data.map((mode) => {
        return {
          y: mode.value,
          name: mode.display,
          color: mode.color,
        }
      }),
    },
  ]

  const chartOptions: Highcharts.Options = {
    chart: {
      width: 200,
      height: 200,
    },
    title: {
      text: `<div style="text-align:center;font-weight:bold;color:#000">
      <span style="font-size:16px">${t(
        'total count'
      )}</span> <br /> <strong style="font-size:35px;font-weight:800">${total}</strong>
      </div>`,
      verticalAlign: 'middle',
      useHTML: true,
      style: {
        zIndex: 1,
        fontFamily: 'Almarai, sans-serif',
      },
    },
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        {title} <span className={classes.subTitle}>{subTitle}</span>
      </Typography>
      <div className={classes.chartWrapper}>
        <Grid container>
          <Grid item xs={4}>
            <PieChart series={series} options={chartOptions} />
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.tableTitle}>{name}</Typography>
            <div className={classes.tableWrapper}>
              {data.map((mode) => (
                <div key={mode.display} className={classes.tableRow}>
                  <span
                    className={classes.tableRowColor}
                    style={{ backgroundColor: mode.color }}
                  />
                  {mode.display}
                  <span
                    className={classes.tableRowValue}
                    style={{ color: mode.color }}
                  >
                    {mode.value}
                  </span>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
