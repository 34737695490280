import { gql } from '@apollo/client'

// IPs page
export const GET_IPS = gql`
  query GetIPs($orgId: Int!) {
    ips(orgId: $orgId) {
      id
      ip
      notes
    }
  }
`

// Apps Page
export const GET_APPS = gql`
  query GetApps($orgId: Int!) {
    apps(orgId: $orgId) {
      id
      name
      key
    }
  }
`

// Services Page
export const GET_SERVICES = gql`
  query GetServices($orgId: Int!) {
    apis(orgId: $orgId) {
      categories {
        services {
          description
          name
          sku
        }
      }
    }
  }
`

// Services Page
export const GET_APIS = gql`
  query GetApis($orgId: Int!) {
    apis(orgId: $orgId) {
      id
      name
      path
      order
      jsonSchema
      webUiSchema
      categories {
        name
        title
        created
        description
        id
        modified
        services {
          jsonSchema
          webUiSchema
          sku
          name
          description
          order
        }
      }
    }
  }
`

// Users page
export const GET_USERS = gql`
  query GetUsers($orgId: Int!) {
    users(orgId: $orgId) {
      lastName
      firstName
      email
      id
    }
  }
`

// API Transactions Page
export const GET_API_TRANSACTIONS = gql`
  query GetApiTransactions($orgId: ID!, $limit: Int, $offset: Int) {
    apiTransactions(organization: $orgId) {
      totalCount
      results(limit: $limit, offset: $offset) {
        created
        id
        ip
        modified
        requestBody
        requestPath
        requestMethod
        requestQueryParams
        requestPathParams
        requestTime
        responseBody
        responseStatusCode
        responseTime
      }
    }
  }
`

// Get user quota
export const GET_CLIENT_QUOTA = gql`
  query GetClientQuota($orgId: Int!) {
    quotas(orgId: $orgId) {
      activationTime
      organization {
        id
        name
        fullLogo
      }
      created
      expirationDate
      expired
      finished
      id
      interval
      modified
      quota
      usedQuota
    }
  }
`

export const FETCH_ORG = gql`
  query FetchOrg {
    organizations {
      fullLogo
      id
      settings
      name
      userPermissions
      userRoles
    }
  }
`

export const FETCH_ORGANIZATION_DETAILS = gql`
  query Organization($pk: Int!) {
    organization(pk: $pk) {
      fullLogo
      name
      userPermissions
      userRoles
    }
  }
`
