import React from 'react'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import utils from '@/core/utils'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useStyle } from './QueriesReviewGuiSpatialInfo.style'

const { numberFormat } = utils

export const QueriesReviewGuiSpatialInfo = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const items = [
    [t('api.parcel_spatial_info.area.by_deed'), data.area.by_deed],
    [t('api.parcel_spatial_info.area.by_plan'), data.area.by_plan],
    [
      t('api.parcel_spatial_info.area.by_real_estate_unit'),
      data.area.by_real_estate_unit,
    ],
  ].map(([title, value]) => [
    <Typography className={classes.dataCell}>{title}</Typography>,
    <Typography className={classes.dataCell}>
      {value ? `${numberFormat(value)} ${t('m2')}` : t('not exist')}
    </Typography>,
  ])

  const labels = [
    {
      color: '#f71900',
      title: 'color according to plan',
    },
    {
      color: '#6c52ff',
      title: 'color according to real estate unit',
    },
  ]

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      {data.image && (
        <div>
          <div className={classes.imageContainer}>
            <img
              src={data.image}
              className={classes.image}
              alt={t('parcel spatial info')}
            />
          </div>

          <div className={classes.labelsContainer}>
            {labels.map((label) => (
              <div className={classes.labelContainer} key={label.title}>
                <div
                  className={classes.coloredLabel}
                  style={{ backgroundColor: label.color }}
                />
                <div className={classes.labelText}>{t(label.title)}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <CardTable items={items} />
    </div>
  )
}
