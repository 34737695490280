import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  emptyCard: {
    borderRadius: 10,
    background: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(2),
    border: '1px solid #e8e6f4',
  },
}))
