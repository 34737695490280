import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  detailsTable: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  code: {
    padding: 10,
    display: 'block',
    direction: 'rtl',
    'border-radius': 6,
    'white-space': 'pre-wrap',
    border: ' 1px solid #ddd',
    background: 'rgba(128, 128, 128, 0.05)',
  },
}))
