import { makeStyles, Theme } from '@material-ui/core'

export const usePrivacyAndUsageStyle = makeStyles((theme: Theme) => ({
  root: {
    lineHeight: 1.6,
  },
  itemsContainer: {
    '& > li': {
      marginBottom: theme.spacing(2),
    },
  },
}))
