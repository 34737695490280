import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  arrowWrapper: {
    position: 'absolute',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    border: 'none',
    cursor: 'pointer',
    boxShadow: theme.shadows[1],
  },
  leftArrow: {
    right: 0,
  },
  rightArrow: {
    left: 0,
  },
  containerClass: {
    textAlign: 'center',
    direction: 'rtl',
  },
  title: {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
}))
