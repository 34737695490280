/* eslint-disable no-underscore-dangle */
import { getFullToken } from '@flint/auth'
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

const responseConfig = (config: AxiosResponse) => {
  return config
}

const responseError = async (
  error: AxiosError & { config: { _retry: boolean } }
) => {
  const originalRequest: AxiosRequestConfig & { _retry: boolean } = error.config
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    const token = getFullToken()

    return Axios({
      ...originalRequest,
      headers: {
        ...originalRequest.headers,
        Authorization: token,
      },
    })
  }
  return Promise.reject(error)
}

export const refreshTokenInterceptor = [responseConfig, responseError]
