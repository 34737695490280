import React, { useState, useEffect, FC } from 'react'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import { LoadingProgress } from '@/components/LoadingProgress'
import { useQuery } from '@flint/graphql'
import useOrg from '@/hooks/useOrg'
import { GET_APIS } from '@/graphql'
import { useQueryState } from '@/hooks/useQueryState'
import { useStyle } from './QueriesContent.style'
import { QueriesContentAPIForm } from './QueriesContentAPIForm'

export const QueriesContent: FC = () => {
  const { executeQueryLoading } = useQueryState()
  const classes = useStyle()

  const {
    activeOrg: { id: orgId },
  } = useOrg()

  const { data, loading } = useQuery(GET_APIS, {
    variables: {
      orgId,
    },
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const [pills, setPills] = useState([])

  const onClickPillButton = (index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    const items =
      data?.apis?.map((pill) => {
        const allServices = pill?.categories?.reduce(
          (prev, curr) => [...prev, ...curr.services],
          []
        )
        return {
          ...pill,
          services: [...new Map(allServices.map((s) => [s.sku, s])).values()],
        }
      }) || []

    setPills(items)
  }, [data])

  return (
    <div className={classes.root}>
      <LoadingProgress loading={loading} />
      <div className={classes.pillsContainer}>
        {pills.map((pill, pillIndex) => (
          <Button
            disabled={executeQueryLoading}
            className={clsx(classes.pillButton, {
              [classes.activePillButton]: activeIndex === pillIndex,
            })}
            key={pill.id}
            onClick={() => onClickPillButton(pillIndex)}
          >
            <span
              className={clsx(classes.pillTitle, {
                [classes.activePillTitle]: activeIndex === pillIndex,
              })}
            >
              {pill.name}
            </span>
          </Button>
        ))}
      </div>

      {pills.map((pill, index) => (
        <div
          key={pill.id}
          style={{
            marginBottom: 15,
            display: activeIndex === index ? 'block' : 'none',
          }}
        >
          <QueriesContentAPIForm {...pill} />
        </div>
      ))}
    </div>
  )
}
