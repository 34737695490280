import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    display: 'flex',
    direction: 'ltr',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  actionIcon: {
    width: 16,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      color: '#666',
      cursor: 'pointer',
    },
  },
}))
