import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2.5, 3.5),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.25),
  },
  headerTitle: {
    fontSize: 13,
    color: '#000',
    fontWeight: 'bold',
  },
  progressContainer: {
    height: 5,
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#ebebeb',
  },
  progressBar: {
    top: 0,
    right: 0,
    height: '100%',
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: '#55abdd',
  },
}))
