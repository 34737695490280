export const pageview = (url) => {
  return (
    window.gtag &&
    window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY, {
      page_path: url,
    })
  )
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  return (
    window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  )
}
