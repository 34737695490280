import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

const useStyle = makeStyles(() => ({
  root: {
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}))

export const MainButton: FC<ButtonProps> = (props) => {
  const classes = useStyle()
  const { children, ...rest } = props
  return (
    <Button className={classes.root} {...rest}>
      {children}
    </Button>
  )
}
