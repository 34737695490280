import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: { height: '100%' },
  addNewIPTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
  },
  IPAddressExample: {
    fontSize: 12,
    color: '#5b5b5b',
    padding: '0 14px',
  },
  actionsContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
  actionButton: {
    fontSize: 15,
    color: '#fff',
    width: '48%',
    borderRadius: 10,
    fontWeight: 'bold',
    padding: theme.spacing(1.4),
  },
  cancelButton: {
    backgroundColor: '#55abdd',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4b9bca',
    },
  },
  formLoaderContainer: {
    display: 'flex',
    height: '60%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
