import React from 'react'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'
import { CreateApplication } from '@/modules/Applications/CreateApplicationPage'

export const CreateApplicationPage = () => {
  const { t } = useTranslation()

  return (
    <Layout
      pageTitle={t('applications')}
      pageSubTitle={t('add new application')}
    >
      <CreateApplication />
    </Layout>
  )
}
