import React from 'react'
import { Typography } from '@material-ui/core'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import utils from '@/core/utils'
import { useTranslation } from '@flint/locales'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { useStyle } from './QueriesReviewGuiDistrictInfo.style'

const { numberFormat, objDeepLoop } = utils

export const QueriesReviewGuiDistrictInfo = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const { image, ...tableData } = data

  const title = `api.${name}_title`

  const modifiedTableData = objDeepLoop(
    tableData,
    (key) => key,
    (value: any, key: string) => {
      if (key === 'area') {
        return `${numberFormat(value)} ${t('m2')}`
      }
      if (key === 'number') {
        return numberFormat(value)
      }

      return value
    }
  )

  const cardTableData = getCardTableDataTree({
    data: modifiedTableData,
    prefix: `api.${name}`,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <div className={classes.imageContainer}>
        <img src={image} className={classes.image} alt={t('district info')} />
      </div>

      <CardTable items={cardTableData} />
    </div>
  )
}
