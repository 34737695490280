import React, { FC } from 'react'
import { DashoardPageStatChart } from '@/modules/DashoardPage/DashoardPageStatChart/DashoardPageStatChart'
import { useTranslation } from '@flint/locales'

export const DashboardPageQueriesStat: FC = () => {
  const { t } = useTranslation()

  const data = [
    { value: 12, display: 'الاستعلامات الناجحة', color: '#2dca69' },
    { value: 15, display: 'الاستعلامات الفاشلة', color: '#00d8ff' },
    { value: 3, display: 'الاستعلامات الملغاة', color: '#55abdd' },
  ]
  return (
    <DashoardPageStatChart
      data={data}
      name={t('details of queries')}
      title={t('queries')}
    />
  )
}
