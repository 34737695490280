import React, { useRef, useState } from 'react'
import { FieldProps } from '@rjsf/core'
import { Dialog, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { KeyboardArrowRight } from '@material-ui/icons'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import clsx from 'clsx'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import utils from '@/core/utils'
import { useStyle } from './QueriesReviewGuiGenericImageGallery.style'
import { QueriesReviewGuiGenericImageGalleryItem } from './QueriesReviewGuiGenericImageGalleryItem'

const { isTrue } = utils

const LeftArrow = (props: any) => {
  const classes = useStyle()
  return (
    <button
      className={clsx(classes.arrowWrapper, classes.leftArrow)}
      type="button"
      {...props}
    >
      <KeyboardArrowLeft />
    </button>
  )
}

const RightArrow = (props: any) => {
  const classes = useStyle()
  return (
    <button
      type="button"
      className={clsx(classes.arrowWrapper, classes.rightArrow)}
      {...props}
    >
      <KeyboardArrowRight />
    </button>
  )
}

export const QueriesReviewGuiGenericImageGallery = (props: FieldProps) => {
  const carouselRef = useRef<any>(null)
  const { t } = useTranslation()
  const [activeImages, setActiveImages] = useState([])

  const classes = useStyle()
  const onImageClick = (images: string[]) => {
    setActiveImages(images)
  }

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`
  const prefix = `api.${name}`

  const responsiveModal = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const onClose = () => {
    setActiveImages([])
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      {!isTrue(data) ? (
        <div className={classes.emptyCard}>{t('no results')}</div>
      ) : (
        <div>
          <Dialog onClose={onClose} open={!!activeImages.length}>
            <div className={classes.modalWrapper}>
              <Carousel
                ref={carouselRef}
                responsive={responsiveModal}
                containerClass={classes.containerClass}
                customLeftArrow={<LeftArrow />}
                customRightArrow={<RightArrow />}
              >
                {activeImages.map((url: string) => (
                  <img
                    style={{
                      maxWidth: '100%',
                      minWidth: '100%',
                    }}
                    src={url}
                    key={url}
                    alt={url}
                  />
                ))}
              </Carousel>
            </div>
          </Dialog>
          {Object.keys(data).length &&
            Object.keys(data ?? []).map((imageKey: string) => (
              <QueriesReviewGuiGenericImageGalleryItem
                title={t(`${prefix}.${imageKey}`)}
                images={data[imageKey]}
                onImageClick={onImageClick}
              />
            ))}
        </div>
      )}
    </div>
  )
}
