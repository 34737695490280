import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  dataCell: {
    fontSize: 14,
    color: '#000',
    fontWeight: 'bold',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  labelsContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
  },
  coloredLabel: {
    top: 2,
    width: 30,
    height: 10,
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#eee',
  },
  labelText: {
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  image: {
    width: '100%',
  },
}))
