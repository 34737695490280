import React from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { FlintCheckboxProps } from './FlintCheckbox.interface'

export const FlintCheckbox = ({
  label,
  subLabel,
  formControlLabelProps,
  ...props
}: FlintCheckboxProps) => (
  <FormControlLabel
    {...formControlLabelProps}
    control={<Checkbox {...props} />}
    label={
      <div>
        <Typography>{label}</Typography>
        <Typography style={{ fontSize: 12 }}>{subLabel}</Typography>
      </div>
    }
  />
)
