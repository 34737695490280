import React from 'react'
import { useQuota } from '@/hooks/useQuota'
import { useTranslation } from '@flint/locales'
import { Typography } from '@material-ui/core'
import { useStyle } from './AvailableQueryBalance.style'

export const AvailableQueryBalance = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const {
    quota: { quota, usedQuota },
  } = useQuota()

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerTitle}>
          {t('available query balance')}
        </Typography>
        <Typography className={classes.headerTitle}>
          {quota ?? 0} / {usedQuota ?? 0}
        </Typography>
      </div>

      <div className={classes.progressContainer}>
        <div
          className={classes.progressBar}
          style={{
            width: `${(usedQuota / quota) * 100}%`,
          }}
        />
      </div>
    </div>
  )
}
