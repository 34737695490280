export enum Path {
  HOME = '/',
  QUERIES = '/queries',
  DASHBOARD = '/dashboard',
  USERS = '/users',
  USERS_ADD = '/users/add',
  SERVICES = '/services',
  API_INTEGRATION = '/docs',
  API_INTEGRATION_INFO = '/docs/info',
  API_INTEGRATION_DOCS = '/docs/swagger',
  APPLICATIONS = '/apps',
  APPLICATIONS_CREATE = '/apps/create',
  IP_ADDRESSES = '/ips',
  QUERY_LOGS = '/logs',
}
