import React, { FC, useState } from 'react'
import useOrg from '@/hooks/useOrg'
import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useDispatch } from 'react-redux'
import { dispatcher } from '@flint/core'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { fetchClientAsync } from '@/redux'
import { useQueryState } from '@/hooks/useQueryState'
import { MainButton } from '@/components/UI'
import { getFullToken } from '@flint/auth'
import { useStyle } from './QueriesAPIForm.style'
import { QueriesContentAPIFormProps } from './QueriesContentAPIForm.interface'
import { QueriesContentAPIFormRequiredServices } from './QueriesContentAPIFormRequiredServices'

export const QueriesContentAPIForm: FC<QueriesContentAPIFormProps> = ({
  path,
  id,
  jsonSchema,
  webUiSchema,
  name: pillName,
  categories,
  services,
}) => {
  const { executeQueryLoading } = useQueryState()
  const { activeOrg } = useOrg()
  const classes = useStyle()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedServices, setSelectedServices] = useState<string[]>([])
  const [formData, setFormData] = useState({})

  const onSbumitExecuteQuery = async () => {
    setErrorMessage(null)
    dispatcher({
      query: {
        payload: {},
        payloadIsNotEmpty: false,
        executeQueryLoading: true,
      },
    })

    const token = getFullToken()
    try {
      const res = await fetch(
        process.env.REACT_APP_API_BASE_URL + path.replace('v1', ''),
        {
          method: 'POST',
          body: JSON.stringify({
            input: formData,
            skus: [...new Set(selectedServices)],
            org_id: activeOrg.id,
          }),
          headers: {
            authorization: token,
            'content-type': 'application/json',
          },
        }
      )

      const data = await res.json()

      if (res.status === 200) {
        dispatcher({
          query: {
            services: services ?? [],
            payload: data,
            payloadIsNotEmpty: !!Object.keys(data).length,
          },
        })

        dispatch({
          reducer: 'query',
          type: 'transactionId',
          payload: res.headers.get('x-transaction-id'),
        })
        dispatch(fetchClientAsync(activeOrg.id)).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
      } else if (data.message) {
        setErrorMessage(data.message)
      }
    } finally {
      dispatch({
        reducer: 'query',
        type: 'executeQueryLoading',
        payload: false,
      })
    }
  }

  return (
    <div className={classes.root}>
      <h3 className={classes.headerTitle}>{pillName}</h3>
      <Typography className={classes.title}>
        {path === 'v1/deed/' ? `${t('api.deed_title')}*` : null}
      </Typography>

      {!!errorMessage && (
        <div className={classes.errorMessage}>{errorMessage}</div>
      )}

      <ReactJsonSchemaForm
        className={classes.align}
        formData={formData}
        schema={jsonSchema}
        uiSchema={webUiSchema}
        setFormData={setFormData}
        disabled={executeQueryLoading}
        onSubmit={onSbumitExecuteQuery}
      >
        <div className={classes.servicesContainer}>
          <QueriesContentAPIFormRequiredServices
            id={id}
            services={services ?? []}
            categories={categories ?? []}
            value={selectedServices}
            disabled={executeQueryLoading}
            setValue={setSelectedServices}
          />

          <div className={classes.executeButtonContainer}>
            <MainButton
              variant="contained"
              color="primary"
              size="large"
              style={{ minWidth: 160 }}
              type="submit"
              disabled={!selectedServices.length || executeQueryLoading}
            >
              {executeQueryLoading ? (
                <CircularProgress style={{ color: '#666' }} size={25} />
              ) : (
                t('execute the query')
              )}
            </MainButton>
          </div>
        </div>
      </ReactJsonSchemaForm>
    </div>
  )
}
