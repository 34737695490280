import { GET_CLIENT_QUOTA } from '@/graphql'
import { graphQlQuery } from '@flint/graphql'
import { dispatcher } from '@flint/utils'

// Async actions
export const fetchClientAsync =
  (orgId: number): any =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      graphQlQuery({
        query: GET_CLIENT_QUOTA,
        variables: {
          orgId,
        },
      })
        .then(({ data, errors }) => {
          const quota = (data?.quotas ?? [])[0] ?? {}
          const error = errors?.find(
            (error) => error?.message === 'NOT INVITED'
          )

          if (error) {
            dispatcher(dispatch, {
              quota: {
                notInvited: true,
              },
            })
          } else if (quota) {
            dispatcher(dispatch, {
              quota: {
                quota: {
                  ...quota,
                  organization: {
                    ...quota?.organization,
                    id: Number(quota?.organization?.id) ?? null,
                  },
                },
              },
            })

            return resolve({
              quota,
            })
          }
          return resolve(null)
        })
        .catch((error) => {
          dispatcher(dispatch, {
            quota: {
              notInvited: true,
            },
          })

          reject(error)
        })
    })
