import { authInterceptor } from './interceptors/requests/auth.interceptor'
import { transformRequestParamsInterceptor } from './interceptors/requests/transformRequestParams.interceptor'
import { refreshTokenInterceptor } from './interceptors/response/refreshToken.interceptor'

export const responseInterceptors = [refreshTokenInterceptor]

// intercetpor execuated before response is finished
export const requestInterceptors = [
  authInterceptor,
  transformRequestParamsInterceptor,
]
