import { Layout } from '@/modules/Layout'
import { UsersPageAddContainer } from '@/modules/UsersPage'
import React from 'react'
import { useTranslation } from '@flint/locales'

export const UsersAddPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('users')}
      pageSubTitle={t('view and create new users')}
    >
      <UsersPageAddContainer />
    </Layout>
  )
}
