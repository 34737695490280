import React from 'react'
import { QueryLogs } from '@/modules/QueryLogs'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const QueryLogsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('query logs')}
      pageSubTitle={t('query logs page subtitle')}
    >
      <QueryLogs />
    </Layout>
  )
}
