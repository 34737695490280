import React, { FC } from 'react'
import { useTranslation } from '@flint/locales'
import { Button, Link } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { Path } from '@/types'
import { useStyle } from './CreateAppButton.style'

export const CreateAppButton: FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Button
      component={Link}
      className={classes.root}
      href={Path.APPLICATIONS_CREATE}
    >
      <AddIcon />
      <span className={classes.title}>{t('add new application')}</span>
    </Button>
  )
}
