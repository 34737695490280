import { Maps } from 'google-map-react'

export const addLocationbutton = (map: any, maps: Maps) => {
  const controlDiv = document.createElement('div')

  const firstChild = document.createElement('div')
  firstChild.style.backgroundColor = '#fff'
  firstChild.style.border = 'none'
  firstChild.style.outline = 'none'
  firstChild.style.width = '40px'
  firstChild.style.height = '40px'
  firstChild.style.borderRadius = '2px'
  firstChild.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
  firstChild.style.cursor = 'pointer'
  firstChild.style.marginRight = '10px'
  firstChild.style.marginTop = '10px'
  firstChild.style.display = 'flex'
  firstChild.style.justifyContent = 'center'
  firstChild.style.alignItems = 'center'
  firstChild.style.padding = '0px'
  firstChild.title = 'Your Location'
  controlDiv.appendChild(firstChild)

  const secondChild = document.createElement('div')
  secondChild.style.margin = '5px'
  secondChild.style.width = '18px'
  secondChild.style.height = '18px'
  secondChild.style.transform = 'scale(1.35)'
  secondChild.style.backgroundImage =
    'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)'
  secondChild.style.backgroundSize = '180px 18px'
  secondChild.style.backgroundPosition = '0px 0px'
  secondChild.style.backgroundRepeat = 'no-repeat'
  secondChild.id = 'you_location_img'
  firstChild.appendChild(secondChild)

  maps.event.addListener(map, 'dragend', () => {
    secondChild.style.backgroundPosition = '0px 0px'
  })

  firstChild.addEventListener('click', () => {
    let imgX = '0'
    const animationInterval = setInterval(() => {
      if (imgX === '-18') imgX = '0'
      else imgX = '-18'
      secondChild.style.backgroundPosition = `${imgX}px 0px`
    }, 500)

    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latlng = new maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          )
          // marker.setPosition(latlng)
          map.setCenter(latlng)
          map.setZoom(14)
          clearInterval(animationInterval)
          secondChild.style.backgroundPosition = '-144px 0px'
        })
      } else {
        clearInterval(animationInterval)
        secondChild.style.backgroundPosition = '0px 0px'
      }
    }
  })

  controlDiv.index = 1
  map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv)
}
