import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  ulRoot: {
    margin: 0,
    borderRadius: 10,
    background: '#fff',
    border: '1px solid #e8e6f4',
    padding: theme.spacing(2.5, 3),
  },
  fieldItem: {
    display: 'flex',
    alignItems: 'center',
    listStyleType: 'none',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    '& path': {
      fill: '#ff0000',
    },
  },
  warningIcon: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1.5),
  },
  fieldText: {
    fontSize: 15,
    color: '#ff0000',
    fontWeight: 'bold',
  },
}))
