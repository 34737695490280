import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    // position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    // paddingTop: 40,
  },
  emptyAppsContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 135px)',
  },
  emptyAppsCotainer: {
    maxWidth: 600,
    width: '100%',
    margin: '0 auto',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #dfdfdf',
    padding: theme.spacing(10, 3),
  },
  emptyAppsText: {
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: 17,
    marginTop: 30,
    marginBottom: theme.spacing(4),
  },
}))
