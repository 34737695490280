import { MainButton } from '@/components/UI'
import { FlintField } from '@/components/UI/FlintForm'
import { Grid, Typography } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from '@flint/locales'
import clsx from 'clsx'
import { useMutation } from '@flint/graphql'
import { ADD_USER } from '@/graphql'
import { createUserValidator } from '@/validators'
import { useStyle } from './UsersPageAddContainer.style'
import { UsersPageAddContainerFromValues } from './UsersPageAddContainer.interface'

export const UsersPageAddContainer: FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const [addUser] = useMutation(ADD_USER)

  const onSubmit = async (
    values: UsersPageAddContainerFromValues,
    { setSubmitting, resetForm }: FormikHelpers<UsersPageAddContainerFromValues>
  ) => {
    try {
      addUser({ variables: { email: values.email } })
      resetForm()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {t('add a new user')}
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Formik
            initialValues={{
              email: '',
              manager: false,
              owner: true,
              developer: false,
            }}
            validationSchema={createUserValidator}
            onSubmit={onSubmit}
          >
            {({ resetForm, isSubmitting }) => (
              <Form>
                <FlintField name="email" label={t('email or mobile number')} />
                <div className={classes.actionsContainer}>
                  <MainButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    size="large"
                    className={clsx(classes.button)}
                  >
                    {t('save')}
                  </MainButton>
                  <MainButton
                    onClick={() => resetForm()}
                    className={clsx(classes.button, classes.cancel)}
                    variant="contained"
                  >
                    {t('cancel')}
                  </MainButton>
                </div>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  )
}
