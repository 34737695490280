import React from 'react'
import { Typography } from '@material-ui/core'
import { FieldProps } from '@rjsf/core'
import { CardTable, CardTableItem } from '@flint/atoms'
import { useTranslation } from '@flint/locales'
import { useStyle } from './QueriesReviewGuiGenericTable.style'

export const QueriesReviewGuiGenericTable = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`
  const prefix = `api.${name}`

  const keys = Object.keys((data ?? [])[0] ?? []).filter(
    (e) => e !== 'coordinates'
  )

  const cellItem = (content) =>
    new CardTableItem({
      content,
      cellProps: {
        style: {
          maxWidth: 50,
          textAlign: 'center',
        },
      },
    })

  const rows = [
    [
      cellItem('#'),
      ...keys.map((item) => (
        <div className={classes.rowTitle}>{t(`${prefix}.${item}`)}</div>
      )),
    ],
    ...(data ?? []).map((row, index) => [
      cellItem(index + 1),
      ...keys.map((k) => row[k]),
    ]),
  ]

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>
      <CardTable items={rows} />
    </div>
  )
}
