import React from 'react'
import { i18n } from '@flint/locales'
import utils from '@/core/utils'

const { strToSnakeCase } = utils

const displayValue = ({ value, dataCellWrapper, translations }) => {
  if (typeof value === 'boolean') {
    value = i18n.t(value ? 'yes' : 'no')
  } else if (translations[value]) {
    value = i18n.t(translations[value])
  }

  return dataCellWrapper(
    value || (value === null || isNaN(value) ? i18n.t('not exist') : value)
  )
}

const getTitle = ({
  baseKey,
  key,
  value,
  prefix,
}: {
  baseKey?: string
  key: string | number
  value: any
  prefix: string
}) => {
  let suffix = key
  prefix = prefix || ''
  baseKey = baseKey ? `.${baseKey}` : ''
  if (value && [Object, Array].includes(value.constructor)) {
    suffix =
      value.type && !isNaN(key as number) ? `${value.type}` : `${key}_title`
  }

  const i18nKey = `${prefix}${strToSnakeCase(`${baseKey}.${suffix}`)}`
  const result = i18n.exists(i18nKey) ? i18n.t(i18nKey) : suffix
  return result
}

const getCardTableNestedData = ({
  data,
  baseKey,
  dataCellWrapper,
  translations,
  prefix,
}) => {
  return Object.entries(data).map(([key, value]) => {
    const isDataTree =
      value &&
      [Object, Array].includes(
        value.constructor as ObjectConstructor | ArrayConstructor
      )
    const title = getTitle({ key, baseKey, value, prefix })

    if (isDataTree) {
      value = getCardTableNestedData({
        data: value,
        baseKey: isNaN(key as any) ? `${baseKey}.${key}` : baseKey,
        translations,
        dataCellWrapper,
        prefix,
      })
    } else {
      value = displayValue({
        value,
        translations,
        dataCellWrapper,
      })
    }

    return [isNaN(key as any) ? title : `#${Number(key) + 1}`, value]
  })
}

export const getCardTableDataTree = ({
  data,
  titleWrapper = (value) => value,
  dataCellWrapper,
  translations,
  prefix,
  excludedKeys,
  DataTree,
}: {
  data: any
  prefix: string
  excludedKeys?: string[]
  titleWrapper?: (title: string | number) => string | number
  dataCellWrapper?: (value: any) => string
  translations?: {
    [key: string]: string
  }
  DataTree?: any
}) => {
  excludedKeys = excludedKeys || []
  translations = translations || {}

  dataCellWrapper = dataCellWrapper || ((value) => value)

  return Object.entries(data)
    .filter(([key]) => !excludedKeys.includes(key))
    .map(([key, value]: any[]) => {
      const isDataTree =
        value &&
        [Object, Array].includes(
          value.constructor as ObjectConstructor | ArrayConstructor
        )
      const title = getTitle({ key, value, prefix })
      let dataTree: any[] = []

      if (isDataTree) {
        if (value.constructor === Object) {
          dataTree = [
            [
              '',
              getCardTableNestedData({
                data: value,
                baseKey: Array.isArray(data) ? '' : key,
                translations,
                dataCellWrapper,
                prefix,
              }),
            ],
          ]
        } else if (value.constructor === Array) {
          dataTree = value.map((item, index) => [
            `#${index + 1}`,
            getCardTableNestedData({
              data: item,
              baseKey: Array.isArray(data) ? '' : key,
              translations,
              dataCellWrapper,
              prefix,
            }),
          ])
        }

        value = <DataTree items={dataTree} />
      } else {
        value = displayValue({
          value,
          translations,
          dataCellWrapper,
        })
      }

      return [titleWrapper(title), value]
    })
}
