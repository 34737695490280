import React, { FC, useState } from 'react'
import { Typography } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { Modal } from '@/components/Modal'
import { useMutation } from '@flint/graphql'
import { DELETE_IP_ADDRESS } from '@/graphql/mutations'
import { useStyle } from './IPAddressesPageAddItemNotesCell.style'
import { IPAddressesPageAddItemNotesCellProps } from './IPAddressesPageAddItemNotesCell.interface'

export const IPAddressesPageAddItemNotesCell: FC<
  IPAddressesPageAddItemNotesCellProps
> = ({ text, id, ip, refetch, setIpFormInitialValues }) => {
  const classes = useStyle()
  const [openModal, setOpenModal] = useState(false)
  const [DeleteIPAddress] = useMutation(DELETE_IP_ADDRESS)
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false)
  const handleModalClose = () => {
    setOpenModal(false)
  }

  const onClickDeleteIP = async () => {
    setConfirmButtonLoading(true)
    try {
      await DeleteIPAddress({
        variables: {
          id,
        },
      })

      refetch()
    } finally {
      setConfirmButtonLoading(false)
      handleModalClose()
    }
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{text}</Typography>
      <div>
        <EditIcon
          className={classes.actionIcon}
          onClick={() => setIpFormInitialValues({ ip, id, notes: text })}
        />
        <DeleteIcon
          className={classes.actionIcon}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <Modal
        open={openModal}
        message="هل تريد حذف هذا ال IP ؟"
        onClickCloseModal={handleModalClose}
        confirmButtonText="نعم"
        onClickConfirmButton={onClickDeleteIP}
        confirmButtonLoading={confirmButtonLoading}
      />
    </div>
  )
}
