const strToCamelCase = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('_', ''))
}

const strToSnakeCase = (text) => {
  return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

const objDeepLoop = (
  data,
  keyFun = (key) => key,
  valueFun = (value) => value
) => {
  return Object.entries(data || {}).reduce(
    (data, [key, value]) => {
      if ((value && value.constructor === Object) || Array.isArray(value)) {
        value = objDeepLoop(value, keyFun, valueFun)
      }

      data[keyFun(key, value)] = valueFun(value, key)

      return data
    },
    data && data.constructor === Object ? {} : []
  )
}

const numberFormat = (number) => {
  return (
    !isNaN(number) && new Intl.NumberFormat().format(Number(number).toFixed(0))
  )
}

const objectToQueryString = (object) => {
  const searchParams = new URLSearchParams()
  Object.keys(object).forEach((key) =>
    searchParams.append(strToSnakeCase(key), object[key])
  )
  return searchParams.toString()
}

function getOsEnv(key, defaultValue) {
  if (typeof process.env[key] !== 'undefined') {
    return process.env[key]
  }

  if (defaultValue !== 'undefined') {
    return defaultValue
  }

  throw new Error(`Environment variable ${key} is not set.`)
}

const printPlaceholer = (value) => value || '-'

export const debounce = (func, wait, immediate) => {
  let timeout
  return (...args) => {
    const later = () => {
      timeout = null
      if (!immediate) func.apply(this, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(this, args)
  }
}

const isTrue = (value) => {
  if (typeof value === 'boolean') {
    return value
  }
  if (value === null) {
    return false
  }
  if (value && value.constructor === Array && value.length === 0) {
    return false
  }
  if (
    value &&
    value.constructor === Object &&
    Object.keys(value).length === 0
  ) {
    return false
  }

  return !!value
}

export default {
  isTrue,
  numberFormat,
  objDeepLoop,
  strToCamelCase,
  strToSnakeCase,
  objectToQueryString,
  getOsEnv,
  printPlaceholer,
  debounce,
}
