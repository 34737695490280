import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  emptyCard: {
    borderRadius: 10,
    background: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(2),
    border: '1px solid #e8e6f4',
  },
  containerClass: {
    textAlign: 'center',
    direction: 'rtl',
  },
  modalWrapper: {
    maxWidth: '80%',
    maxHeight: '80%',
    margin: ' 10px auto',
    padding: 20,
  },
  arrowWrapper: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    border: 'none',
    cursor: 'pointer',
    boxShadow: theme.shadows[1],
  },
  leftArrow: {
    right: 0,
  },
  rightArrow: {
    left: 0,
  },
}))
