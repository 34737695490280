import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    '& ::placeholder': {
      fontFamily: 'Almarai, sans-serif !important',
    },
    '& :-ms-input-placeholder': {
      fontFamily: 'Almarai, sans-serif !important',
    },
    '& ::-ms-input-placeholder': {
      fontFamily: 'Almarai, sans-serif !important',
    },
  },
  appNameLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    display: 'inline-block',
    marginBottom: theme.spacing(1.5),
    color: theme.palette.common.black,
  },
  appNameInput: {
    height: 53,
    fontSize: 16,
    width: '100%',
    color: '#16151a',
    borderRadius: 10,
    border: '1px solid #dfdfdf',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3.5),
  },
  actionsContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1.2),
    justifyContent: 'space-between',
  },
  actionButton: {
    fontSize: 15,
    color: '#fff',
    width: '48%',
    borderRadius: 10,
    fontWeight: 'bold',
    padding: theme.spacing(1.4),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
