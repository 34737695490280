import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 30,
    padding: theme.spacing(0.8, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}))
