import React, { FC } from 'react'
import ReactJsonView from 'react-json-view'
import utils from '@/core/utils'
import { useQueryState } from '@/hooks/useQueryState'

const { strToSnakeCase, objDeepLoop } = utils

export const QueriesReviewJson: FC = () => {
  const { payload } = useQueryState()

  const JSONPaylod = objDeepLoop(payload, (key) => strToSnakeCase(key))

  return (
    <div style={{ direction: 'ltr' }}>
      <ReactJsonView src={JSONPaylod} />
    </div>
  )
}
