import React from 'react'
import { DocumentationsPage } from '@/modules/DocumentationsPage/DocumentationsPage'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const ApiDocsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('api integration docs')}
      pageSubTitle={t('api integration docs page subtitle')}
    >
      <DocumentationsPage />
    </Layout>
  )
}
