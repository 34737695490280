import React from 'react'
import { Grid } from '@material-ui/core'
import { DashboardPageQueriesStat } from '@/modules/DashoardPage/DashboardPageQueriesStat'
import { DashoardPagePeriodUsageStat } from './DashoardPagePeriodUsageStat'

export const DashboardPage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardPageQueriesStat />
      </Grid>
      <Grid item xs={6}>
        <DashoardPagePeriodUsageStat />
      </Grid>
    </Grid>
  )
}
