import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { FieldProps } from '@rjsf/core'
import { Warning as WarningIcon } from '@/icons'
import { useStyle } from './QueriesReviewGuiFieldData.style'

export const QueriesReviewGuiFieldData = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <ul className={classes.ulRoot}>
        {data.map(({ type, description }) => (
          <li className={classes.fieldItem} key={description}>
            <WarningIcon className={classes.warningIcon} />
            <span className={classes.fieldText}>
              {type + (description ? `: ${description}` : '')}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}
