import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles(() => ({
  root: {
    borderRadius: 10,
    overflow: 'hidden',
    border: '1px solid #e8e6f4',
    '& .gm-style-iw.gm-style-iw-c': {
      '&::after': {
        background: '#f0eff8',
      },
      padding: 0,
      '& .gm-style-iw-d': {
        overflow: 'hidden !important',
      },
    },
  },
}))
