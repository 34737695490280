import React, { useState, useEffect, FC } from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { useQuery } from '@flint/graphql'
import { GET_SERVICES } from '@/graphql/queries'
import useOrg from '@/hooks/useOrg'
import { useNavigate } from 'react-router-dom'
import { Certification as CertificationIcon } from '@/icons'
import { useStyle } from './Services.style'

export const Services: FC = () => {
  const {
    activeOrg: { id: orgId },
  } = useOrg()
  const classes = useStyle()
  const navigate = useNavigate()
  const [services, setServices] = useState([])
  const { data } = useQuery(GET_SERVICES, {
    variables: {
      orgId,
    },
  })

  useEffect(() => {
    if (data) {
      const categories = data?.apis?.map((api) => api.categories)
      const categoryServices = [
        ...categories
          .map((cat) =>
            cat.reduce((prev, curr) => [...prev, ...curr.services], [])
          )
          .reduce((prev, curr) => [...prev, ...curr], []),
      ]
      const uniqueServices = [
        ...new Map(categoryServices.map((s) => [s.sku, s])).values(),
      ]
      setServices(uniqueServices)
    }
  }, [data])

  const redirectToQueries = (service) => navigate(`/queries?service=${service}`)

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {services?.map((service) => (
          <Grid item md={4} key={service.sku}>
            <div className={classes.serviceContainer}>
              <div className={classes.serviceItem}>
                <div className={classes.serviceIcon}>
                  <CertificationIcon />
                </div>
                <div className={classes.serviceDetails}>
                  <Typography className={classes.serviceTitle}>
                    {service.name}
                  </Typography>
                  <Typography className={classes.serviceSku}>
                    SKU : {service.sku}
                  </Typography>
                  <Typography className={classes.serviceDescription}>
                    {service.description}
                  </Typography>
                </div>
              </div>
              <Button
                className={classes.subscribeButton}
                onClick={() => {
                  redirectToQueries(service.sku)
                }}
              >
                تجربة الخدمة
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
