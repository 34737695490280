import FileSaver from 'file-saver'
import BaseUtils from './_base'

export * from './theme'

const downloadXhrFile = (
  responseBody,
  filename,
  ext = 'pdf',
  blobType = 'application/pdf'
) => {
  const file = new Blob([responseBody], {
    type: blobType,
  })
  FileSaver.saveAs(file, `${filename}.${ext}`)
}

export default {
  ...BaseUtils,
  downloadXhrFile,
}
