import React, { FC } from 'react'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useMutation } from '@flint/graphql'
import { ADD_APP } from '@/graphql'
import { Form, Formik, FormikHelpers } from 'formik'
import { FlintField } from '@/components/UI/FlintForm'
import { createAppValidator } from '@/validators'
import { MainButton } from '@/components/UI'
import { useQuota } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { Path } from '@/types'
import { useStyle } from './CreateApplication.style'
import { CreateApplicationFormValues } from './CreateApplication.interface'

export const CreateApplication: FC = () => {
  const classes = useStyle()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    quota: {
      organization: { id: orgId },
    },
  } = useQuota()
  const [addApp] = useMutation(ADD_APP)

  const onSbumitHandler = async (
    values: CreateApplicationFormValues,
    { setSubmitting }: FormikHelpers<CreateApplicationFormValues>
  ) => {
    setSubmitting(true)
    try {
      await addApp({
        variables: {
          ...values,
          orgId,
        },
      })

      navigate(Path.APPLICATIONS)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={classes.root}>
      <Formik
        validationSchema={createAppValidator}
        onSubmit={onSbumitHandler}
        initialValues={{
          name: '',
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <Grid container>
              <Grid item md={6}>
                <FlintField name="name" label={t('application name')} />

                <Grid container>
                  <Grid item xs={12} className={classes.actionsContainer}>
                    <MainButton
                      className={classes.actionButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t('save')}
                    </MainButton>
                    <MainButton
                      onClick={() => resetForm()}
                      className={clsx(
                        classes.actionButton,
                        classes.cancelButton
                      )}
                    >
                      {t('cancel')}
                    </MainButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}
