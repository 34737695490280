import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: theme.spacing(4),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '48%',
    borderRadius: 10,
    minHeight: 50,
    fontSize: 15,
    fontWeight: 'bold',
  },
  cancel: {
    backgroundColor: '#55abdd',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4b9bca',
    },
  },
}))
