import React, { FC, useEffect, useState } from 'react'
import { Typography, Collapse, IconButton } from '@material-ui/core'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons'
import { useTranslation } from '@flint/locales'
import { FlintCheckbox } from '@/components/UI/FlintForm'
import { useStyle } from './QueriesContentAPIFormRequiredServicesCategory.style'
import { QueriesContentAPIFormRequiredServicesCategoryProps } from './QueriesContentAPIFormRequiredServicesCategory.interface'

export const QueriesContentAPIFormRequiredServicesCategory: FC<
  QueriesContentAPIFormRequiredServicesCategoryProps
> = ({ category, value, disabled, setValue }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false)

  const onToggleSelectAll = (_, selected) => {
    setIsSelected(selected)

    const skus = category.services.map(({ sku }) => sku)

    if (selected) {
      setValue([...value, ...skus.filter((sku) => !value.includes(sku))])
    } else {
      setValue(value.filter((el) => !skus.includes(el)))
    }
  }

  const onSelectService = (selected, sku) => {
    if (selected && !value.includes(sku)) {
      setValue([...value, sku])
    } else if (!selected && value.includes(sku)) {
      setValue(value.filter((el) => el !== sku))
    }
  }

  useEffect(() => {
    setIsSelected(category.services.every(({ sku }) => value.includes(sku)))
    setIsIndeterminate(
      !!category.services.some(({ sku }) => value.includes(sku))
    )
  }, [value])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <FlintCheckbox
          name={category.name}
          label={category.title}
          value={isSelected}
          disabled={disabled}
          checked={isSelected || isIndeterminate}
          onChange={onToggleSelectAll}
          indeterminate={isIndeterminate && !isSelected}
        />

        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? (
            <KeyboardArrowUpIcon fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" />
          )}
        </IconButton>
      </div>

      <Collapse in={isCollapsed}>
        <div className={classes.servicesContainer}>
          {category.services.map(({ name, sku, description }) => (
            <div className={classes.serviceContainer} key={sku}>
              <FlintCheckbox
                name={sku}
                label={t(name)}
                disabled={disabled}
                checked={value.includes(sku)}
                onChange={(_, value) => onSelectService(value, sku)}
              />

              <Typography className={classes.description}>
                {t(description)}
              </Typography>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}
