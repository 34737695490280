import React, { FC } from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useStyle } from './DocumentationsPage.style'

export const DocumentationsPage: FC = () => {
  const classes = useStyle()

  return (
    <div className={classes.root}>
      <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />
    </div>
  )
}
