import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  dataCell: {
    fontSize: 14,
    color: '#000',
    fontWeight: 'bold',
  },
}))
