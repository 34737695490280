import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { HomePage } from '@/modules/HomePage/Home'
import { ApplicationsPage } from '@/pages/apps'
import { CreateApplicationPage } from '@/pages/apps/create'
import { DashboardPage } from '@/pages/dashboard'
import { ApiPage } from '@/pages/docs'
import { ApiInfoPage } from '@/pages/docs/info'
import { ApiDocsPage } from '@/pages/docs/swagger'
import { IPAddressesPage } from '@/pages/ips'
import { QueryLogsPage } from '@/pages/logs'
import { HowToUse } from '@/pages/privacy'
import { QueriesPage } from '@/pages/queries'
import { ServicesPage } from '@/pages/services'
import { UsersPage } from '@/pages/users'
import { UsersAddPage } from '@/pages/users/add'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/apps" element={<ApplicationsPage />} />
      <Route path="/apps/create" element={<CreateApplicationPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/docs" element={<ApiPage />} />
      <Route path="/docs/info" element={<ApiInfoPage />} />
      <Route path="/docs/swagger" element={<ApiDocsPage />} />
      <Route path="/ips" element={<IPAddressesPage />} />
      <Route path="/logs" element={<QueryLogsPage />} />
      <Route path="/privacy" element={<HowToUse />} />
      <Route path="/queries" element={<QueriesPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/add" element={<UsersAddPage />} />
    </Routes>
  )
}
