export const colors = [
  '#005f96',
  '#55abdd',
  '#2dca69',
  '#00d8ff',
  '#ffce00',
  '#a0ca2d',
  '#ff6c00',
  '#0058ff',
  '#2dca69',
  '#AB47BC',
  '#5C6BC0',
  '#4DB6AC',
  '#00796B',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
  '#2d4bca',
  '#55abdd',
  '#ffce00',
  '#00d8ff',
  '#2dca69',
]
