import { createStyles, makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: '#f4f4f4f2',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: 9,
    },
    hide: {
      display: 'none',
    },
  })
)
