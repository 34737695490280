import { useState, useEffect, useCallback } from 'react'
import { Props as IMapProps, Maps } from 'google-map-react'
import { useDispatch } from 'react-redux'
import { addLocationbutton } from '@/lib'

interface ReturnTypes {
  mapProps: IMapProps
  googleMap: any
  googleMaps: any
  resetMapPosition: () => void
}

export const useMap = (props: IMapProps = {}) => {
  const [googleMap, setGmap] = useState(null)
  const [googleMaps, setGmaps] = useState<Maps | null>(null)
  const [myLoactionAdded, setMyLoactionAdded] = useState(false)
  const dispatch = useDispatch()

  const handleApiLoaded = useCallback(
    ({ map, maps }: { map: any; maps: Maps }) => {
      if (maps && map) {
        setGmap(map)
        setGmaps(maps)
        dispatch({
          reducer: 'flint.map',
          type: 'mapInstance',
          payload: map,
        })
        dispatch({
          reducer: 'flint.map',
          type: 'googleMaps',
          payload: maps,
        })
      }
    },
    []
  )
  const defaultCenter = { lat: 23.8859, lng: 45.0792 }
  const defaultZoom = 5

  let bootstrapURLKeys = {}
  if (props.bootstrapURLKeys) {
    bootstrapURLKeys = props.bootstrapURLKeys
    // eslint-disable-next-line no-param-reassign
    delete props.bootstrapURLKeys
  }

  // move to place when search change
  const onPlacesChanged = (place: any) => {
    if (!place || !Object.keys(place).length || !googleMaps) {
      return
    }

    // For each place, get the icon, name and location.
    const bounds = new googleMaps.LatLngBounds()
    if (!place.geometry) {
      return
    }
    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport)
    } else {
      bounds.extend(place.geometry.location)
    }
    googleMap.fitBounds(bounds)
  }

  useEffect(() => {
    dispatch({
      reducer: 'flint.map',
      type: 'inputChange',
      payload: onPlacesChanged,
    })

    return () => {
      dispatch({
        reducer: 'flint.map',
        type: 'inputChange',
        payload: null,
      })
    }
  }, [googleMaps])

  // use effect on google maps and map
  useEffect(() => {
    if (
      googleMap &&
      googleMaps &&
      [undefined, true].includes(props.hasMyLocation) &&
      !myLoactionAdded
    ) {
      setMyLoactionAdded(true)
      addLocationbutton(googleMap, googleMaps)
    }
  }, [googleMap, googleMaps, props.hasMyLocation])

  const defaultOptions: IMapProps = {
    bootstrapURLKeys: {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
      language: 'ar',
      region: 'SA',
      ...bootstrapURLKeys,
    },
    defaultCenter,
    defaultZoom,
    yesIWantToUseGoogleMapApiInternals: true,
    onGoogleApiLoaded: handleApiLoaded,
    resetBoundsOnResize: true,
    options: {
      zoomControlOptions: {
        position: googleMaps && googleMaps.ControlPosition.RIGHT_BOTTOM,
      },
    },
    ...props,
  }

  const resetMapPosition = () => {
    if (googleMap) {
      googleMap.setCenter(defaultCenter)
      googleMap.setZoom(defaultZoom)
    }
  }

  const options: ReturnTypes = {
    googleMap,
    googleMaps,
    mapProps: defaultOptions,
    resetMapPosition,
  }

  return options
}
