import React from 'react'
import { QueriesNotInvited } from '@/modules/Queries/QueriesNotInvited'
import { QueriesContent } from '@/modules/Queries/QueriesContent/QueriesContent'
import { QueriesReview } from '@/modules/Queries/QueriesReview/QueriesReview'
import { Layout } from '@/modules/Layout'
import { useQuota } from '@/hooks'
import { useTranslation } from '@flint/locales'
import { PAGE_TITLE_POS } from '@flint/types'

export const QueriesPage = () => {
  const { t } = useTranslation()
  const { notInvited } = useQuota()

  return (
    <Layout
      pageTitle={t('query')}
      enableDetailsView={!notInvited}
      pageSubTitle={t(
        'experimenting with query services, viewing results, and exporting them'
      )}
      pageTitlePos={PAGE_TITLE_POS.TOP}
    >
      {notInvited ? (
        <QueriesNotInvited />
      ) : (
        <>
          <QueriesContent />
          <QueriesReview />
        </>
      )}
    </Layout>
  )
}
