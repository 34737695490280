import React, { FC } from 'react'
import Form from '@rjsf/core'
import { RJSFPreviewerProps } from './RJSFPreviewer.interface'

export const RJSFPreviewer: FC<RJSFPreviewerProps> = ({
  schema,
  uiSchema,
  formData,
  fields,
  children,
}: RJSFPreviewerProps) => {
  return (
    <div>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        fields={fields}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
    </div>
  )
}
