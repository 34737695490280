import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    boxShadow: '0 7px 22px 0 rgba(0, 0, 0, 0.1)',
  },
  appIcon: {
    height: 50,
    minWidth: 50,
    maxWidth: 50,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  appItem: {
    display: 'flex',
    padding: '10px 10px 0',
  },
  appDetails: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  appTitleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& ::placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
    '& :-ms-input-placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
    '& ::-ms-input-placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
  },
  appActions: {
    display: 'flex',
  },
  appActionIcon: {
    width: 20,
    color: '#555',
    cursor: 'pointer',
    marginLeft: theme.spacing(1.2),
    '&:hover': {
      color: '#888',
    },
  },
  appTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  appKeyContainer: {
    position: 'relative',
    '& ::placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
    '& :-ms-input-placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
    '& ::-ms-input-placeholder': {
      fontSize: 16,
      opacity: 0.5,
      color: '#aaa',
    },
  },
  appKeyActions: {
    top: 0,
    right: 0,
    height: 42,
    color: '#fff',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1.2),
  },
  appKeyAction: {
    width: 20,
    cursor: 'pointer',
    '&:hover': {
      color: '#eee',
    },
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  appKeyInput: {
    height: 42,
    fontSize: 24,
    width: '100%',
    color: '#fff',
    borderRadius: 10,
    border: '1px solid #005f96',
    padding: theme.spacing(0, 2),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(1.3),
    backgroundColor: theme.palette.primary.main,
  },
  appSubTitle: {
    fontSize: 16,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },
  subscribeButton: {
    fontSize: 15,
    color: '#fff',
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  confirmButton: {
    fontSize: 15,
    fontWeight: 'bold',

    padding: theme.spacing(1, 4),
  },
}))
