import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notInvitedBanner: {
    marginBottom: theme.spacing(4),
  },
  notInvitedContainer: {
    maxWidth: 900,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #dfdfdf',
    padding: theme.spacing(7, 2),
    borderRadius: theme.spacing(1),
  },
  notInvitedTitle: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: theme.spacing(2.3),
    marginBottom: theme.spacing(1.6),
  },
  supportEmail: {
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: theme.spacing(2.6),
    marginBottom: theme.spacing(1.6),
    color: theme.palette.primary.main,
  },
  notInvitedSubTitle: {
    margin: 0,
    fontSize: theme.spacing(2),
  },
}))
