import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  chartWrapper: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    border: 'solid 1px #dcdcdc',
    height: 485,
    padding: theme.spacing(30 / 8),
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontSize: 15,
    fontWeight: 800,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    color: '#525252',
    fontSize: 14,
    fontWeight: 'normal',
  },
  tableWrapper: {},
  tableRow: {
    borderTop: 'solid 1px #eaeaea',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '&:last-of-type': {
      borderBottom: 'solid 1px #eaeaea',
    },
  },
  tableTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: theme.spacing(2),
  },
  tableRowColor: {
    marginRight: theme.spacing(2),
    width: 14,
    height: 6,
    borderRadius: 10,
    display: 'inline-block',
  },
  tableRowValue: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: 'auto',
  },
}))
