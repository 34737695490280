import React, { FC, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { AppProgress } from '@/components/UI/AppProgress'
import { useQuery } from '@flint/graphql'
import { GET_APPS } from '@/graphql/queries'
import { Path } from '@/types'
import { useQuota } from '@/hooks'
import { useStyle } from './AppPage.style'
import { AppPageItem } from './AppPageItem'

export const AppPage: FC = () => {
  const classes = useStyle()
  const {
    quota: {
      organization: { id: orgId },
    },
  } = useQuota()
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(GET_APPS, {
    variables: {
      orgId,
    },
  })
  const apps = useMemo(() => (data && data.apps) || [], [data])

  return (
    <div className={classes.root}>
      <AppProgress
        data={apps}
        active={loading}
        noData={{
          message: 'there is no applications',
          button: {
            onClick: Path.APPLICATIONS_CREATE,
            content: t('add new application'),
          },
        }}
      >
        <Grid container spacing={3}>
          {apps.map((app) => (
            <Grid item md={6} key={app.key}>
              <AppPageItem app={app} refetch={refetch} />
            </Grid>
          ))}
        </Grid>
      </AppProgress>
    </div>
  )
}
