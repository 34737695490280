export enum iDTypes {
  NationalID = 'nid',
  IqamaNumber = 'iqama',
  CommercialRegistrationNumber = 'crno',
}

interface ILocation {
  id: number
  name: string
}

interface IArea {
  number: number
  text: string
}

interface IIdentity {
  id: string
  type: iDTypes
}
interface IOwned {
  area: number
  percentage: string
}
interface ILimit {
  type: string
  description: string
}

interface IOwners {
  name: string
  identity: IIdentity
  birth_date: Date
  is_dead: boolean
  owned: IOwned
}

interface IBorder {
  direction: string
  limit: ILimit
  length: IArea
}

interface IRealEstate {
  region: ILocation
  type: string
  land_number: string
  plan_number: string
  neighborhood: string
  location_description: string
  has_limit_and_border: true
  limit_description: string
  remark: string
  area: IArea
  borders: IBorder[]
}

export interface IQueriesApiResponse {
  serialNumber: number
  issueDate: Date
  city: ILocation
  status: string
  note: string
  isCondtrained: boolean
  is_halt: boolean
  is_pawned: boolean
  is_testament: boolean
  share: null
  deed_number: number
  issue_place: ILocation
  area: IArea
  owners: IOwners[]
  borders: IBorder[]
  real_estates: IRealEstate[]
}

export interface IQueriesRequestParams {
  number: string
  owner_id: string
  id_type: iDTypes
}

export interface IOrganization {
  name: string
  id: number
  fullLogo: string
  abstractLogo: string
  userPermissions?: Array<string>
}

export enum UserPermissions {
  ADD_LAYER = 'add_layer',
  ADD_ORG = 'add_organization',
  ADD_RECORD = 'add_record',
  ADD_USER = 'add_user',
  CHANGE_ORG = 'change_organization',
  CHANGE_USER = 'change_user',
  DELETE_LAYER = 'delete_layer',
  DELETE_ORG = 'delete_organization',
  DELETE_RECORD = 'delete_record',
  DELETE_USER = 'delete_user',
  EDIT_LAYER = 'edit_layer',
  EDIT_RECORD = 'edit_record',
  VIEW_LAYER = 'view_layer',
  VIEW_ORG = 'view_organization',
  VIEW_RECORD = 'view_record',
  VIEW_USER = 'view_user',
}
