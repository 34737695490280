import React, { useEffect } from 'react'
import { HttpInstance, HttpServices } from '@/api'
import { FlintApolloProvider } from '@flint/graphql'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import {
  FlintReduxProvider,
  AuthenticationProvider,
  IAuthenticationSuccessCallbackProps,
} from '@flint/core'
import * as gtag from '@/lib/gtag'
import { fetchUserOrgs } from './redux/org/org.async'
import { AppRoutes } from './AppRoutes'

HttpInstance.create(HttpServices.MAKAN)

export const App = () => {
  const location = useLocation()

  useEffect(() => {
    gtag.pageview(location.pathname)
  }, [location])

  const fetchClientData = ({ dispatch }: IAuthenticationSuccessCallbackProps) =>
    dispatch(fetchUserOrgs())

  return (
    <FlintApolloProvider>
      <div id="app-root">
        <FlintReduxProvider>
          <AuthenticationProvider onSuccessCallback={fetchClientData}>
            <AppRoutes />
          </AuthenticationProvider>
        </FlintReduxProvider>
      </div>
    </FlintApolloProvider>
  )
}

export default Sentry.withProfiler(App)
