import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {},
  label: {
    marginBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: 'bold',
    color: '#16151a',
  },
  rootInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 10,
      },
    },
  },
}))
