import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  pillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3.5),
  },
  pillButton: {
    position: 'relative',
    width: '163px',
    backgroundColor: '#f8f8f8',
    border: 'solid 1px #E2E2E2',
    borderRadius: 0,
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#555',
  },
  activePillButton: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    borderRight: 'none',
  },
  pillTitle: {
    fontSize: '13.01px',
    display: 'block',
    fontWeight: 500,
    color: '#5A5A5A',
  },
  activePillTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  executeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))
