import { gql } from '@apollo/client'

export const ADD_APP = gql`
  mutation AddApp($name: String!, $orgId: String!) {
    createApp(input: { name: $name, organization: $orgId }) {
      id
    }
  }
`
export const UPDATE_APP = gql`
  mutation UpdateApp($name: String!, $id: Int!) {
    updateApp(input: { name: $name, id: $id }) {
      id
      name
    }
  }
`
export const DISABLE_APP = gql`
  mutation DisableApp($id: Int!) {
    disableApp(id: $id) {
      ok
    }
  }
`
export const ADD_USER = gql`
  mutation AddUser($email: String) {
    createUser(input: { email: $email }) {
      id
    }
  }
`

export const ADD_IP_ADDRESS = gql`
  mutation AddIPAddress($ip: String!, $notes: String!, $orgId: String!) {
    createIp(input: { ip: $ip, notes: $notes, organization: $orgId }) {
      id
      notes
      organization
    }
  }
`
export const UPDATE_IP_ADDRESS = gql`
  mutation UpdateIp($ip: String!, $id: Int!, $orgId: String!, $notes: String) {
    updateIp(input: { ip: $ip, id: $id, organization: $orgId, notes: $notes }) {
      id
      notes
      organization
    }
  }
`

export const DELETE_IP_ADDRESS = gql`
  mutation DeleteIPAddress($id: Int!) {
    deleteIp(id: $id) {
      ok
    }
  }
`
