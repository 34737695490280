import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { Map } from '@/modules/Queries/QueriesReview/QueriesReviewGui/QueriesReviewGuiMap'
import { useStyle } from './QueriesReviewGuiPointsOfInterest.style'

export const PointsOfInterest = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const createMapInfoWindow = ({ item }) => {
    const poiRows = getCardTableDataTree({
      data: item,
      prefix: `api.${name}`,
      excludedKeys: ['coordinates'],
    })

    return <CardTable items={poiRows} />
  }

  const details = data.details || []

  const poiSummary = getCardTableDataTree({
    data: data.summary || {},
    prefix: `api.${name}`,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <div style={{ marginBottom: 15 }}>
        <Map
          height={450}
          items={details}
          infoWindowOffset={160}
          MapInfoWindow={createMapInfoWindow}
        />
      </div>

      <CardTable items={poiSummary} />
    </div>
  )
}
