import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  rowTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#7c7c7c',
    marginBottom: 27,
  },
}))
