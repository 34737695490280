import React from 'react'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const ApiInfoPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('api integration info')}
      pageSubTitle={t('api integration info page subtitle')}
    >
      <div>Hello from API info</div>
    </Layout>
  )
}
