import { UserPermissions } from '@/types'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store.interface'

type PermissionKey = keyof typeof UserPermissions

const useOrg = () => {
  const {
    activeOrg = {} as any,
    orgs,
    loadingOrgs,
  } = useSelector((state: RootState) => {
    return state.org
  })

  const permissions = useMemo(() => {
    return activeOrg.userPermissions || []
  }, [activeOrg])

  const hasPermissionTo = useCallback(
    (key: PermissionKey) => {
      return permissions.includes(UserPermissions[key])
    },
    [permissions]
  )

  return {
    organizations: orgs,
    activeOrg,
    loadingOrgs,
    permissions,
    hasPermissionTo,
  }
}

export default useOrg
