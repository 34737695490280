import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import utils from '@/core/utils'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { useStyle } from './QueriesReviewGuiGenericDataTree.style'

const { isTrue } = utils

export const QueriesReviewGuiGenericDataTree = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const cardTableData = getCardTableDataTree({
    data: data ?? {},
    prefix: `api.${name}`,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      {!isTrue(data) ? (
        <div className={classes.emptyCard}>{t('no results')}</div>
      ) : (
        <CardTable items={cardTableData} />
      )}
    </div>
  )
}
