import React, { FC, useRef } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import { Button, Typography } from '@material-ui/core'
import { useStyle } from './QueriesReviewGuiGenericImageGalleryItem.style'
import { QueriesReviewGuiGenericImageGalleryItemProps } from './QueriesReviewGuiGenericImageGalleryItem.interface'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const LeftArrow = (props: any) => {
  const classes = useStyle()
  return (
    <button
      className={clsx(classes.arrowWrapper, classes.leftArrow)}
      type="button"
      {...props}
    >
      <KeyboardArrowLeft />
    </button>
  )
}

const RightArrow = (props: any) => {
  const classes = useStyle()
  return (
    <button
      type="button"
      className={clsx(classes.arrowWrapper, classes.rightArrow)}
      {...props}
    >
      <KeyboardArrowRight />
    </button>
  )
}

export const QueriesReviewGuiGenericImageGalleryItem: FC<
  QueriesReviewGuiGenericImageGalleryItemProps
> = ({ title, images, onImageClick }) => {
  const classes = useStyle()
  const caroselRef = useRef<any>()
  // prevent rendering the component if there are no images
  if (!images.length) return null

  const onImageClickHandler = () => {
    onImageClick(images)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Carousel
        ref={caroselRef}
        containerClass={classes.containerClass}
        infinite
        ssr
        responsive={responsive}
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
      >
        {images.map((url: string, index: number) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions

          <Button onClick={onImageClickHandler}>
            <img
              style={{ cursor: 'pointer' }}
              src={url}
              key={url}
              alt={`${title}-${index}`}
              height={100}
              width="90%"
            />
          </Button>
        ))}
      </Carousel>
    </div>
  )
}
