import React from 'react'
import { PrivacyAndUsage } from '@/modules/PrivacyAndUsagePage'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const HowToUse = () => {
  const { t } = useTranslation()
  return (
    <Layout pageTitle={t('privacy policy and usage')}>
      <PrivacyAndUsage />
    </Layout>
  )
}
