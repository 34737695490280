import React from 'react'
import { Services } from '@/modules/Services'
import { useTranslation } from '@flint/locales'
import { Layout } from '@/modules/Layout'

export const ServicesPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('services')}
      pageSubTitle={t('services page subtitle')}
    >
      <Services />
    </Layout>
  )
}
