import { useTranslation } from '@flint/locales'
import { Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'
import { Warning as WarningIcon } from '@/icons'
import { AlertMessageProps } from './AlertMessage.interface'
import { useStyle } from './AlertMessage.style'

export const AlertMessage: FC<AlertMessageProps> = ({
  hide = false,
  message,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Paper
      className={clsx(classes.root, {
        [classes.hide]: hide,
      })}
      elevation={0}
    >
      <WarningIcon />
      <Typography className={classes.text}>{t(message)}</Typography>
    </Paper>
  )
}
