import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { Map } from '@/modules/Queries/QueriesReview/QueriesReviewGui/QueriesReviewGuiMap'
import { useStyle } from './QueriesReviewGuiTripleAddress.style'

export const QueriesReviewGuiTripleAddress = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const mapItems = [data]
  const hasMap = data.coordinates && data.coordinates.constructor === Object

  const cardTableData = getCardTableDataTree({
    data: {
      nationalAddress: data.national_address || {},
      plan: data.plan || {},
      coordinates: data.coordinates || {},
    },
    prefix: `api.${name}`,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      {hasMap && (
        <div style={{ marginBottom: 15 }}>
          <Map height={220} items={mapItems} updateFitBoundZoomLevelBy={-3} />
        </div>
      )}

      <CardTable items={cardTableData} />
    </div>
  )
}
