import React, { FC, useState } from 'react'
import { PDF as PdfIcon } from '@/icons'
import { DetailsWrapper } from '@flint/core'
import { MainButton } from '@/components/UI'
import { ButtonsGroup } from '@/components'
import { CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { LoadingProgress } from '@/components/LoadingProgress'
import { useQueryState } from '@/hooks/useQueryState'
import { queries } from '@/api'
import { QueriesReviewGui } from '@/modules/Queries/QueriesReview/QueriesReviewGui/QueriesReviewGui'
import { QueriesReviewJson } from '@/modules/Queries/QueriesReview/QueriesReviewJson/QueriesReviewJson'
import { useTranslation } from '@flint/locales'
import webUtils from '@/core/utils/index.web'
import { useStyle } from './QueriesReview.style'

enum ViewTypes {
  JSON = 'json',
  GUI = 'gui',
}

export const QueriesReview: FC = () => {
  const { payloadIsNotEmpty, executeQueryLoading, transactionId } =
    useQueryState()

  const [loading, setLoading] = useState(false)
  const PdfIconComponent = loading ? (
    <CircularProgress size={20} style={{ marginLeft: 10 }} color="secondary" />
  ) : (
    <PdfIcon style={{ marginLeft: 10, fill: 'white' }} />
  )

  const [viewType, setViewType] = useState<ViewTypes>(ViewTypes.GUI)
  const classes = useStyle()
  const { t } = useTranslation()

  const downloadPdf = async () => {
    try {
      setLoading(true)
      const results = await queries.executeGetQuery(
        `/export/${transactionId}/`,
        undefined,
        {
          baseURL: process.env.REACT_APP_API_BASE_URL.replace('v1', ''),
          method: 'get',
          responseType: 'blob',
        }
      )

      const now = Date.now()
      webUtils.downloadXhrFile(results.request.response, `report_${now}`)
    } finally {
      setLoading(false)
    }
  }

  const LeftComponent = () => (
    <>
      <MainButton
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={downloadPdf}
        style={{
          alignSelf: 'flex-start',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {PdfIconComponent}
        {t('download report')}
      </MainButton>

      <ButtonsGroup
        sm
        active={viewType}
        onClick={setViewType}
        items={[
          {
            text: t('graphical interface'),
            value: ViewTypes.GUI,
          },
          {
            text: t('JSON format'),
            value: ViewTypes.JSON,
          },
        ]}
      />
    </>
  )

  const header: {
    LeftComponent: () => JSX.Element
    title: string
  } = {
    title: t('the result of the query'),
    LeftComponent: null,
  }

  if (payloadIsNotEmpty) {
    header.LeftComponent = LeftComponent
  }

  return (
    <DetailsWrapper
      header={header}
      message={t('please complete the query form to view the data')}
      isDataLength
    >
      <LoadingProgress loading={executeQueryLoading} />
      <div className={clsx({ [classes.hide]: viewType !== ViewTypes.GUI })}>
        <QueriesReviewGui />
      </div>
      <div className={clsx({ [classes.hide]: viewType !== ViewTypes.JSON })}>
        <QueriesReviewJson />
      </div>
    </DetailsWrapper>
  )
}
