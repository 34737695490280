export const defaultChartOptions = {
  chart: {
    type: 'line',
  },
  title: {
    text: 'مؤشر الخط الزمني للصفقات',
  },
  subtitle: {
    text: '',
  },
  yAxis: {
    title: {
      text: 'القيمة',
    },
  },
  xAxis: {
    title: {
      text: 'التاريخ',
    },
    categories: [],
    labels: {
      step: 1,
    },
  },
  legend: {
    enabled: false,
  },

  plotOptions: {
    series: {
      allowPointSelect: true,
      enableMouseTracking: true,
      dataLabels: {
        enabled: true,
      },
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2030,
    },
  },
  series: [],
  credits: {
    enabled: false,
  },
}
