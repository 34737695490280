import { CircularProgress, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from '@flint/locales'
import { MainButton } from '@/components/UI/MainButton/MainButton'
import { Warning as WarningIcon } from '@/icons'
import { useStyle } from './AppProgress.style'
import { AppProgressProps } from './AppProgress.interface'

export const AppProgress: FC<AppProgressProps> = ({
  active = false,
  children,
  data,
  noData = {
    disabled: false,
    message: '',
    button: {
      disabled: false,
      content: '',
    },
  },
}) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { disabled: noDataDisabled, message, button } = noData || {}
  const {
    content: buttonContent,
    disabled: buttonDisabled,
    onClick,
  } = button || {}
  // return content when loading is disabled and there data
  if (!active && data && data.length) {
    return children
  }

  // Return loading content when data is still loading
  if (active) {
    return (
      <div className={classes.root}>
        <CircularProgress />
        <Typography>{t('please wait')}</Typography>
      </div>
    )
  }
  // return null if the no data object disabled
  if (noDataDisabled) {
    return null
  }

  const onButtonClick = (e) => {
    if (onClick && typeof onClick === 'function') {
      onClick(e)
    }
    if (typeof onClick === 'string') {
      navigate(onClick)
    }
  }
  // Return this card to indicate that the data is empty
  return (
    <div className={classes.root}>
      <div className={classes.emptyAppsContent}>
        <div className={classes.emptyAppsCotainer}>
          <WarningIcon />
          <Typography className={classes.emptyAppsText}>
            {t(message)}
          </Typography>
          {buttonDisabled ? null : (
            <MainButton
              variant="contained"
              color="primary"
              onClick={onButtonClick}
            >
              {buttonContent}
            </MainButton>
          )}
        </div>
      </div>
    </div>
  )
}
