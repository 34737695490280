import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(7),
    backgroundColor: '#f7f7f7',
    [theme.breakpoints.up('lg')]: {
      minHeight: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1420,
    borderRadius: 60,
    overflow: 'hidden',
    backgroundColor: '#fff',
    '& > div': {
      borderRadius: 60,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      '& > div': {
        borderRadius: 0,
        padding: theme.spacing(3),
      },
    },
  },
  detailsGrid: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(6),
    backgroundColor: '#c2e2f2',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  appInfographicContainer: {
    marginBottom: theme.spacing(4),
  },
  appInfographic: {
    maxWidth: '70%',
  },
  description: {
    fontSize: 19,
    lineHeight: 2,
    color: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  moreInfo: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: theme.spacing(2.3, 0),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  headingAppStoreIcons: {
    display: 'inline-flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  copyright: {
    fontSize: 17,
    color: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  aboutGrid: {
    textAlign: 'center',
    padding: theme.spacing(6),
    backgroundSize: 220,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 180,
      padding: theme.spacing(3),
    },
  },
  appLogoContainer: {
    width: '100%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  appLogo: {
    width: '100%',
    maxWidth: 280,
  },
  whatIsMakan: {
    fontSize: 46,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2.5),
    color: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
  },
  whatIsMakanDescription: {
    fontSize: 19,
    color: '#5b5b5b',
    lineHeight: 1.9,
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginBottom: theme.spacing(4),
    },
  },
  subscribeContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: 'solid 1px #ededed',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  subscriberPortal: {
    fontSize: 19,
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    '& svg': {
      width: 28,
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  subscriberPortalText: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
    },
  },
  loginInButton: {
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #000',
    padding: theme.spacing(1, 3),
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: theme.spacing(1, 2),
    },
  },
  loginInButtonText: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  contactUsContainer: {
    borderRadius: 40,
    border: 'solid 1px #ededed',
    padding: theme.spacing(5, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 4),
    },
  },
  contactUs: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  contactEmail: {
    fontSize: 18,
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}))
