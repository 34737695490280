import React from 'react'
import { AppPage } from '@/modules/Applications/AppPage'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'
import { CreateAppButton } from '@/modules/Applications/CreateAppButton'

export const ApplicationsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageSubTitle={t('applications page subtitle')}
      pageTitle={t('applications')}
      toolbarAdornment={<CreateAppButton />}
    >
      <AppPage />
    </Layout>
  )
}
