import { getFullToken } from '@flint/auth'
import { AxiosRequestConfig } from 'axios'

export const authInterceptor = async (config: AxiosRequestConfig) => {
  /**
   * Set Authorization header to request.
   *
   */
  const clonedConfig = { ...config }
  const token = getFullToken()

  if (token) {
    clonedConfig.headers.Authorization = token
  }

  return clonedConfig
}
