import Axios, { AxiosInstance } from 'axios'
import { objectDeepLoop, strToCamelCase } from '@flint/utils'
import { requestInterceptors, responseInterceptors } from './globalInterceptors'

export enum HttpServices {
  MAKAN = 'makan',
}

// venrsions
export enum HtttpServiceVersions {
  v1 = 'v1',
  v2 = 'v2',
}

export class HttpInstance {
  // create a hash table to store instances {service: AxiosInstance}
  private static instances:
    | Record<HttpServices, Record<HtttpServiceVersions, AxiosInstance>>
    | any = {}

  // create an axios instance
  public static create(
    service: HttpServices,
    version: HtttpServiceVersions = HtttpServiceVersions.v1
  ) {
    // check if instance already created
    if (HttpInstance.has(service, version)) {
      return
    }

    // get url from config
    const baseURL: string = process.env.REACT_APP_API_BASE_URL

    const serviceInstance = HttpInstance.instances[service]
    const axiosInstance = Axios.create({
      baseURL,
      transformResponse: [
        ...Axios.defaults.transformResponse,
        (data) => {
          return objectDeepLoop(data, (key) => strToCamelCase(key))
        },
      ],
    })

    // implement global request interceptors
    requestInterceptors.forEach((interceptor) => {
      axiosInstance.interceptors.request.use(interceptor)
    })
    // implement global response interceptors
    responseInterceptors.forEach((interceptor) => {
      if (Array.isArray(interceptor)) {
        // if interceptor is an array the like the following
        // [(config) => config, (error) => error]
        // which means one for response config and the other for  rejection
        axiosInstance.interceptors.response.use(interceptor[0], interceptor[1])
      } else {
        // this considered the response config
        axiosInstance.interceptors.response.use(interceptor)
      }
    })
    // if this is the first time of create that service instance
    if (!serviceInstance) {
      HttpInstance.instances[service] = { [version]: axiosInstance }
    } else {
      // if service is already create with at least one versions
      HttpInstance.instances[service][version] = axiosInstance
    }
  }

  // check if an instance exist
  static has(
    service: HttpServices,
    version: HtttpServiceVersions = HtttpServiceVersions.v1
  ): AxiosInstance | boolean {
    // There is no instances yet
    if (
      !HttpInstance.instances ||
      !Object.keys(HttpInstance.instances).length
    ) {
      return false
    }
    // if service does not exist in the instances
    if (!(service in HttpInstance.instances)) {
      return false
    }
    // if there is no such a version
    if (!(version in HttpInstance.instances[service])) {
      return false
    }
    return HttpInstance.instances[service][version]
  }

  // get in instance
  public static get(
    service: HttpServices,
    version: HtttpServiceVersions = HtttpServiceVersions.v1
  ): AxiosInstance {
    const instance = HttpInstance.has(service, version)
    if (!instance) {
      const msg = `${service} with version ${version} does exist`
      throw new Error(msg)
    }
    return instance as AxiosInstance
  }
}
