/* eslint-disable */
import React, { FC } from 'react'
import Form from '@rjsf/core'
import { hasValue } from '@flint/utils'
import { useTranslation } from '@flint/locales'
import { Typography } from '@material-ui/core'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { AlertMessage } from '@/components/AlertMessage'
import { useQueryState } from '@/hooks/useQueryState'
import { JsonSchemaViewer } from '@/components/JsonSchemaViewer'
import { RJSFPreviewer } from '@/components/RJSFPreviewer'
import { QueriesReviewGuiDeedData } from './QueriesReviewGuiDeedData'
import { QueriesReviewGuiGenericDataTree } from './QueriesReviewGuiGenericDataTree'
import { QueriesReviewGuiHistoricalTransactions } from './QueriesReviewGuiHistoricalTransactions'
import { QueriesReviewGuiTripleAddress } from './QueriesReviewGuiTripleAddress'
import { QueriesReviewGuiInfrastructure } from './QueriesReviewGuiInfrastructure'
import { QueriesReviewGuiFieldData } from './QueriesReviewGuiFieldData'
import { PointsOfInterest } from './QueriesReviewGuiPointsOfInterest'
import { CommercialLicense } from './QueriesReviewGuiCommercialLicense'
import { QueriesReviewGuiGenericMap } from './QueriesReviewGuiGenericMap'
import { QueriesReviewGuiGenericTable } from './QueriesReviewGuiGenericTable'
import { QueriesReviewGuiSpatialInfo } from './QueriesReviewGuiSpatialInfo'
import { QueriesReviewGuiDistrictInfo } from './QueriesReviewGuiDistrictInfo'
import { QueriesReviewGuiChart } from './QueriesReviewGuiChart'
import { useStyle } from './QueriesReviewGui.style'
import { QueriesReviewGuiGenericImageGallery } from './QueriesReviewGuiGenericImageGallery'

const customFields = {
  transactions_timeseries: QueriesReviewGuiChart,
  deed: QueriesReviewGuiDeedData,
  transactions: QueriesReviewGuiHistoricalTransactions,
  '3d_address': QueriesReviewGuiTripleAddress,
  infrastructure: QueriesReviewGuiInfrastructure,
  field_data: QueriesReviewGuiFieldData,
  poi: PointsOfInterest,
  shop_licenses_info: CommercialLicense,
  valid_licenses_by_municipality: QueriesReviewGuiGenericMap,
  valid_licenses_on_building: QueriesReviewGuiGenericTable,
  expired_licenses_on_building: QueriesReviewGuiGenericTable,
  parcel_spatial_info: QueriesReviewGuiSpatialInfo,
  district_info: QueriesReviewGuiDistrictInfo,
  park_images: QueriesReviewGuiGenericImageGallery,
}

export const QueriesReviewGui: FC = () => {
  const { payload, services, payloadIsNotEmpty, executeQueryLoading } =
    useQueryState()

  const mappedPayload = payload

  const classes = useStyle()
  const { t } = useTranslation()

  const payloadKeys = Object.keys(mappedPayload).filter(
    (key) => mappedPayload[key] !== undefined
  )

  const getCustomFields = (sku: string) => {
    return customFields[sku]
      ? { [sku]: customFields[sku] }
      : { [sku]: QueriesReviewGuiGenericDataTree }
  }

  return (
    <div className={classes.root}>
      {!payloadIsNotEmpty && !executeQueryLoading && (
        <div className={classes.alertContainer}>
          <div className={classes.alertWrapper}>
            <AlertMessage
              hide={false}
              message={t('please complete query form')}
            />
          </div>
        </div>
      )}

      {payloadIsNotEmpty &&
        payloadKeys.map((k) => {
          const requiredService = services?.find(
            (s) => s.sku?.toLocaleLowerCase() === k
          )
          const { sku, name, jsonSchema, webUiSchema } = requiredService ?? {}
          const skuName = sku?.toLocaleLowerCase()
          const formData = payload[skuName]
          const uiSchema = hasValue(webUiSchema)
            ? webUiSchema
            : { 'ui:field': skuName, 'ui:sku': skuName }

          return (
            <div key={sku} style={{ marginBottom: 15 }}>
              {hasValue(formData) ? (
                <RJSFPreviewer
                  schema={jsonSchema}
                  uiSchema={uiSchema}
                  formData={formData}
                  fields={getCustomFields(skuName)}
                  children
                />
              ) : (
                <div>
                  <Typography className={classes.title}>{name}</Typography>
                  <div className={classes.emptyCard}>{t('no results')}</div>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}
