import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IQueriesApiResponse } from '@/types'

import {
  HttpInstance,
  HttpServices,
  HtttpServiceVersions,
} from '@/api/HttpInstance'

export class queries {
  static http(version: HtttpServiceVersions = HtttpServiceVersions.v1) {
    return HttpInstance.get(HttpServices.MAKAN, version)
  }

  // execute query
  public static executeQuery(
    path: string,
    data: undefined,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<IQueriesApiResponse[]>> {
    return queries.http().post(path, data, {
      ...options,
    })
  }

  public static executeGetQuery(
    path: string,
    data,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<IQueriesApiResponse[]>> {
    return queries.http().get(path, {
      ...options,
    })
  }
}
