import React from 'react'
import { IFlintConfig } from '@flint/types'
import { useLocation as router } from 'react-router-dom'
import { resources as i18lResources } from '@/core/localization'
import { plainReducers } from './src/redux/states'
import LogoTitle from './src/assets/images/logo-title.png'
import LogoIcon from './src/assets/images/makan-logo.png'

export const flintConfig = (): IFlintConfig => ({
  reducers: plainReducers,
  i18l: {
    resources: i18lResources,
  },
  auth: {
    scopes: process.env.REACT_APP_AUTH_SCOPE as string,
    domain: process.env.REACT_APP_AUTH_DOMAIN as string,
    tokenPath: process.env.REACT_APP_AUTH_TOKEN_PATH as string,
    clientId: process.env.REACT_APP_AUTH_WEB_CLIENT_ID as string,
    authorizePath: process.env.REACT_APP_AUTH_AUTHORIZE_PATH as string,
    redirectUri: process.env.REACT_APP_AUTH_WEB_CALLBACK_URL as string,
    revokePath: process.env.REACT_APP_AUTH_REVOKE_PATH as string,
    accountsBaseUrl: process.env.REACT_APP_ACCOUNTS_BASE_URL as string,
  },
  graphQl: {
    uri: process.env.REACT_APP_GRAPHQL_URL as string,
  },
  layout: {
    theme: {
      palette: {
        primary: {
          main: '#005f96',
        },
        secondary: {
          main: '#015F96',
        },
      },
    },
    drawerToolbar: {
      LogoIcon: <img src={LogoIcon} alt="LogoIcon" width={50} />,
      FullLogo: <img src={LogoTitle} alt="LogoTitle" height={50} />,
    },
  },
  google: {
    maps: {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
  },
  router,
})

export default flintConfig
