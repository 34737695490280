import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {},
  executeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  errorMessage: {
    borderRadius: 10,
    color: '#dd5555',
    background: '#fdf6f6',
    padding: theme.spacing(2),
    border: '1px solid #dd5555',
    marginBottom: theme.spacing(2),
  },
  servicesContainer: {
    paddingTop: theme.spacing(2),
  },
  align: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  headerTitle: {
    color: '#005f96',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#55abdd',
    marginBottom: theme.spacing(1.2),
  },
}))
