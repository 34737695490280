import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {},
  serviceContainer: {
    height: '100%',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    boxShadow: '0 7px 22px 0 rgba(0, 0, 0, 0.1)',
  },
  serviceIcon: {
    height: 50,
    minWidth: 50,
    maxWidth: 50,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  serviceItem: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  serviceDetails: {
    paddingLeft: theme.spacing(2.3),
  },
  serviceTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  serviceSku: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },
  serviceDescription: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
  subscribeButton: {
    fontSize: 15,
    color: '#fff',
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))
