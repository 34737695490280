import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#55abdd',
    marginBottom: theme.spacing(1.2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.6),
  },
  selectAllButton: {
    padding: theme.spacing(0.6, 0),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkboxContainer: {
    height: '100%',
    padding: theme.spacing(0, 2),
  },
  headerContainer: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(0.8),
    flexDirection: 'column',
  },
  description: {
    fontSize: 12,
    color: '#5c5c5c',
    padding: theme.spacing(0.3, 0),
    margin: theme.spacing(0, 4),
  },
  withoutDescription: {
    fontSize: 12,
    color: '#5c5c5c',
    padding: theme.spacing(0.5, 0),
    margin: theme.spacing(0, 4),
  },
  withoutContainer: {
    height: '100%',
    borderRadius: 5,
    border: '1px solid #e7e7e7',
    padding: theme.spacing(1.5, 3.5),
  },
  skuText: {
    fontSize: 12,
    color: '#5c5c5c',
    fontWeight: 'bold',
  },
  skuValue: {
    fontSize: '.5rem',
    color: '#5c5c5c',
  },
  collapsiblecontainer: {
    display: 'flex',
    borderBottom: '1px solid #e7e7e7',
  },
  collapsibleaccordion: {
    borderRadius: 5,
    border: '1px solid #e7e7e7',
  },
  noServicesNotification: {
    textAlign: 'center',
    color: '#777',
  },
}))
