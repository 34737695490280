import { IQuotaState } from '@/redux/store.interface'

export const quotaInitialState = (): IQuotaState => ({
  notInvited: false,
  quota: {
    id: null,
    created: null,
    modified: null,
    organization: {
      id: null,
      fullLogo: null,
      name: null,
    },
    quota: null,
    usedQuota: null,
    activationTime: null,
    interval: null,
    intervalUnit: null,
    expired: null,
    finished: null,
    expirationDate: null,
  },
})
