import React from 'react'
import { Typography } from '@material-ui/core'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import { useTranslation } from '@flint/locales'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { useStyle } from './QueriesReviewGuiDeedData.style'

export const QueriesReviewGuiDeedData = (props: FieldProps) => {
  const classes = useStyle()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props ?? {}
  const { t } = useTranslation()

  const translations = {
    nid: t('National ID'),
    iqama: t('Iqama Number'),
    crno: t('Commercial Registration Number'),
  }

  const title = `api.${name}_title`

  const cardTableData = getCardTableDataTree({
    data,
    translations,
    prefix: `api.${name}`,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <CardTable items={cardTableData} />
    </div>
  )
}
