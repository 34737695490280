import React from 'react'
import { IPAddresses } from '@/modules/IPAddressesPage'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const IPAddressesPage = () => {
  const { t } = useTranslation()

  return (
    <Layout
      pageTitle={t('ip addresses')}
      pageSubTitle={t('ip addresses page subtitle')}
    >
      <IPAddresses />
    </Layout>
  )
}
