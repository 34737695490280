import { CardTable } from '@flint/atoms'
import { Grid, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import ReorderIcon from '@material-ui/icons/Reorder'
import { useTranslation } from '@flint/locales'
import { useStyle } from './UsersPageCard.style'
import { UserCardProps } from './UserCard.interface'

export const UsersPageCard: FC<UserCardProps> = ({
  id,
  firstName,
  lastName,
  email,
}) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const DetailsComponent = (
    <Grid justify="space-between" container className={classes.detailsWrapper}>
      <Grid item>
        <Typography>{t('details')}</Typography>
      </Grid>
      <Grid item>
        <ReorderIcon fontSize="small" />
      </Grid>
    </Grid>
  )

  const data = [
    [`${t('the user')} #${id}`, DetailsComponent],
    [t('name'), `${firstName} ${lastName}`],
    [t('email'), email],
    [t('phone'), '+966 547 122554'],
  ]

  return (
    <Grid item xs={4}>
      <CardTable items={data} />
    </Grid>
  )
}
