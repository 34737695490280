import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.64)',
    height: 48,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
  },
  itemColor: {
    width: 10,
    height: 4,
    borderRadius: 10,
    backgroundColor: '#ff0000',
  },
  itemText: {
    fontSize: 10,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
}))
