/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import {
  MenuItem,
  FormGroup,
  FormLabel,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import { Field, FieldHookConfig, useField, useFormikContext } from 'formik'
import { useStyle } from './FlintField.style'

interface FlintFieldProps {
  label: string
  name: string
  items?: {
    value: string
    label: string
  }[]
}
export const FlintField: FC<
  TextFieldProps & FlintFieldProps & FieldHookConfig<string>
> = ({ label, items = [], ...props }) => {
  const [, meta] = useField(props)
  const { name } = props
  const { status } = useFormikContext()
  const { error, touched } = meta
  const errorText = (touched && error) || (status && status[name]) || null
  const hasError = !!(touched && error) || !!(status && status[name])
  const classes = useStyle()

  return (
    <FormGroup>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Field
        as={TextField}
        fullWidth
        placeholder={label}
        helperText={errorText}
        style={{ marginBottom: 20 }}
        classes={{
          root: classes.rootInput,
        }}
        variant="outlined"
        error={hasError}
        {...props}
      >
        {props.select &&
          (items || []).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Field>
    </FormGroup>
  )
}
