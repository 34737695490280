import React, { FC } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { colors } from '@/core'
import { PieChartProps } from './PieChart.interface'

export const PieChart: FC<PieChartProps> = ({ options, series }) => {
  const chartOptions: Highcharts.Options = {
    series,
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    chart: { type: 'pie', width: 200 },

    tooltip: {
      useHTML: true,
      backgroundColor: '#fff',
      style: {
        textAlign: 'right',
        fontFamily: 'Almarai, sans-serif',
        fontSize: '10px',
        zIndex: 2,
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      itemStyle: {
        fontFamily: 'Almarai, sans-serif',
        fontSize: '10px',
      },
      useHTML: true,
      rtl: true,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 768,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                dataLabels: {
                  useHTML: true,
                  enabled: false,
                },
              },
            },
          },
        },
      ],
    },
    colors,
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          useHTML: true,
          crop: false,
          style: {
            color: '#343434',
          },
          connectorColor: 'silver',
        },
      },
    },
    ...options,
  }
  return <HighchartsReact options={chartOptions} hieghCharts={Highcharts} />
}
