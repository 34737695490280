import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  serviceContainer: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: theme.spacing(0.8),
  },
  subTitle: {
    fontSize: 14,
    color: '#5c5c5c',
    marginBottom: theme.spacing(1.6),
  },
  wrapperContainer: {
    paddingTop: theme.spacing(0.8),
  },
  alertContainer: {
    paddingTop: 'calc(50%)',
  },
  alertWrapper: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  emptyCard: {
    borderRadius: 10,
    background: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(2),
    border: '1px solid #e8e6f4',
  },
}))
