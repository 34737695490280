import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from '@flint/locales'
import { Path } from '@/types'
import { ILink } from '@flint/types'
import { DrawerUserProfile } from '@flint/core'
import {
  Services as ServicesIcon,
  QueryLogs as QueryLogsIcon,
  IpAddress as IPAddressesIcon,
  Applications as ApplicationsIcon,
  ApiIntegration as ApiIntegrationIcon,
  ApiIntegrationDocs as ApiIntegrationDocsIcon,
  ApiIntegrationInfo as ApiIntegrationInfoIcon,
  Queries as QueriesIcon,
} from '@/icons'
import { ClientInfo } from './ClientInfo'
import { AvailableQueryBalance } from './AvailableQueryBalance'

export const useLinks: () => ILink[] = (): ILink[] => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const buildDynamicProps = (path: Path): Partial<ILink> => {
    return {
      isActive: location.pathname === path,
      link: path,
      onClick: async () => navigate(path),
    }
  }

  const links: any[] = [
    {
      component: DrawerUserProfile,
    },
    {
      component: ClientInfo,
      showOn: ['drawerOpened', 'auth'],
    },
    {
      component: AvailableQueryBalance,
      showOn: ['drawerOpened', 'auth'],
    },
    {
      isDivider: true,
      showOn: ['drawerOpened', 'auth'],
    },
    {
      title: t('query'),
      IconComponent: QueriesIcon,
      ...buildDynamicProps(Path.QUERIES),
    },
    {
      title: t('services'),
      IconComponent: ServicesIcon,
      ...buildDynamicProps(Path.SERVICES),
    },
    {
      title: t('api integration'),
      IconComponent: ApiIntegrationIcon,
      // ...buildDynamicProps(Path.API_INTEGRATION),
      hasArrow: true,
      root: true,
      subItems: [
        {
          title: t('api integration info'),
          IconComponent: ApiIntegrationInfoIcon,
          ...buildDynamicProps(Path.API_INTEGRATION_INFO),
        },
        {
          title: t('api integration docs'),
          IconComponent: ApiIntegrationDocsIcon,
          ...buildDynamicProps(Path.API_INTEGRATION_DOCS),
        },
      ],
    },
    {
      isDivider: true,
    },
    // {
    //   title: t('dashboard'),
    //   IconComponent: DashboardIcon,
    //   ...buildDynamicProps(Path.DASHBOARD),
    // },
    {
      title: t('applications'),
      IconComponent: ApplicationsIcon,
      ...buildDynamicProps(Path.APPLICATIONS),
    },
    {
      title: t('ip addresses'),
      IconComponent: IPAddressesIcon,
      ...buildDynamicProps(Path.IP_ADDRESSES),
    },
    // {
    //   title: t('users'),
    //   IconComponent: UsersIcon,
    //   ...buildDynamicProps(Path.USERS),
    // },
    {
      title: t('query logs'),
      IconComponent: QueryLogsIcon,
      ...buildDynamicProps(Path.QUERY_LOGS),
    },
  ]

  return links
}
