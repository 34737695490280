import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 250,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    width: '100%',
    textAlign: 'center',
  },
  text: {
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: 17,
    marginTop: 30,
  },
  hide: {
    display: 'none',
  },
}))
