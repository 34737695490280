import { Layout } from '@/modules/Layout'
import { UsersPageContainer } from '@/modules/UsersPage'
import React from 'react'
import { useTranslation } from '@flint/locales'

export const UsersPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('users')}
      pageSubTitle={t('view and create new users')}
    >
      <UsersPageContainer />
    </Layout>
  )
}
