import React, { FC, Fragment, useState } from 'react'
import {
  Box,
  Table,
  Collapse,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import utils from '@/core/utils'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useStyle } from './QueryLogsItem.style'
import { QueryLogsItemProps } from './QueryLogsItem.interface'

const { strToSnakeCase } = utils

const toJson = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

const prettifyJson = (value: string) => JSON.stringify(value, ['\n'], 2)

export const QueryLogsItem: FC<QueryLogsItemProps> = ({ transaction }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyle()

  const JSONKeys = ['requestQueryParams', 'requestBody', 'responseBody']

  const details = [
    'requestMethod',
    'requestPath',
    'requestPathParams',
    'requestQueryParams',
    'requestBody',
    'responseBody',
  ].map((key) => ({
    value:
      JSONKeys.includes(key) && !!transaction[key] ? (
        <pre className={classes.code}>
          {prettifyJson(toJson(transaction[key]))}
        </pre>
      ) : (
        transaction[key]
      ),
    key: strToSnakeCase(key),
  }))

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell align="right">{transaction.responseStatusCode}</TableCell>
        <TableCell align="right">
          {transaction.responseTime &&
            moment(transaction.responseTime).format('Z hh:mm:ss YYYY-MM-DD')}
        </TableCell>
        <TableCell align="right">
          {transaction.requestTime &&
            moment(transaction.requestTime).format('Z hh:mm:ss YYYY-MM-DD')}
        </TableCell>
        <TableCell align="right">{transaction.ip}</TableCell>
        <TableCell align="right">{transaction.name}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography align="right" variant="h5">
              Details
            </Typography>
            <Box className={classes.detailsTable}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {details.map((item) => (
                    <TableRow key={item.key}>
                      <TableCell align="right">{item.value || '-'}</TableCell>
                      <TableCell
                        align="right"
                        component="th"
                        style={{ fontWeight: 'bold' }}
                      >
                        {item.key}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
