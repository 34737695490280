import React from 'react'

export enum ViewTypes {
  JSON = 'json',
  GUI = 'gui',
}

export interface ButtonsGroupItem {
  text: string
  value: ViewTypes.JSON | ViewTypes.GUI
}

export interface ButtonsGroupProps {
  active: ViewTypes
  items: ButtonsGroupItem[]
  sm?: boolean
  onClick?: (
    value: ViewTypes.JSON | ViewTypes.GUI,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
