import React, { FC } from 'react'
import { DashoardPageStatChart } from '@/modules/DashoardPage/DashoardPageStatChart/DashoardPageStatChart'
import { useTranslation } from '@flint/locales'

export const DashoardPagePeriodUsageStat: FC = () => {
  const { t } = useTranslation()

  const data = [
    { value: 12, display: 'يناير', color: '#ffce00' },
    { value: 15, display: 'فبراير', color: '#2d4bca' },
    { value: 3, display: 'مارس', color: '#008691' },
    { value: 3, display: 'ابريل', color: '#16109d' },
  ]
  return (
    <DashoardPageStatChart
      data={data}
      name={t('usage details')}
      title={t('months of use')}
      subTitle={t(
        'the top 5 months in terms of queries during the current year'
      )}
    />
  )
}
