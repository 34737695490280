import React from 'react'
import { FieldProps } from '@rjsf/core'
import { CardTable } from '@flint/atoms'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useStyle } from './QueriesReviewGuiInfrastructure.style'

export const QueriesReviewGuiInfrastructure = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const dataCell = (value) => {
    return <Typography className={classes.dataCell}>{value}</Typography>
  }

  const infrastructureRows = Object.entries(data).map(([key, value]) => [
    dataCell(t(`api.infrastructure.${key}`)),
    dataCell(t(`api.infrastructure.${value}`)),
  ])

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <CardTable items={infrastructureRows} />
    </div>
  )
}
