import React from 'react'
import {
  Fade,
  Button,
  Backdrop,
  CircularProgress,
  Modal as ModalMui,
} from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import { useTranslation } from '@flint/locales'
import { useStyle } from './Modal.styles'
import { ModalProps } from './Modal.interface'

export function Modal({
  open,
  message,
  HeroIcon,
  onClickCloseModal,
  confirmButtonText,
  confirmButtonProps,
  confirmButtonLoading,
  onClickConfirmButton,
}: ModalProps) {
  const classes = useStyle()
  const { t } = useTranslation()

  const onCloseModal = () => {
    if (
      !confirmButtonLoading &&
      onClickCloseModal &&
      onClickCloseModal.constructor === Function
    ) {
      onClickCloseModal()
    }
  }

  return (
    <ModalMui
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <ClearIcon className={classes.clearIcon} onClick={onCloseModal} />

          {HeroIcon && <HeroIcon />}

          <div className={classes.message}>{t(message)}</div>

          <Button
            color="primary"
            variant="contained"
            aria-label="confirm register"
            disableElevation
            className={classes.confirmButton}
            onClick={onClickConfirmButton}
            {...confirmButtonProps}
          >
            {confirmButtonLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              <span>{t(confirmButtonText || 'ok')}</span>
            )}
          </Button>
        </div>
      </Fade>
    </ModalMui>
  )
}
