import React from 'react'
import { FieldProps } from '@rjsf/core'
import { Map } from '@/modules/Queries/QueriesReview/QueriesReviewGui/QueriesReviewGuiMap'
import { CardTable } from '@flint/atoms'
import { useTranslation } from '@flint/locales'
import { Typography } from '@material-ui/core'
import { getCardTableDataTree } from '@/core/utils/cardTableDataTree'
import { useStyle } from './QueriesReviewGuiCommercialLicense.style'

export const CommercialLicense = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`
  const prefix = `api.${name}`

  const createMapInfoWindow = ({ item }) => {
    const rows = getCardTableDataTree({
      data: item,
      prefix,
      excludedKeys: ['coordinates'],
    })

    return <CardTable items={rows} />
  }

  const locations = data.locations || []

  const byActivities = getCardTableDataTree({
    data: data.by_activities || {},
    prefix,
  })

  const byStreet = getCardTableDataTree({
    data: data.by_street || {},
    prefix,
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <div style={{ marginBottom: 15 }}>
        <Map
          height={450}
          items={locations}
          infoWindowOffset={160}
          MapInfoWindow={createMapInfoWindow}
        />
      </div>

      <Typography className={classes.subTitle}>{t('activities')}</Typography>

      <CardTable items={byActivities} />

      <Typography className={classes.subTitle}>
        {t('street activities')}
      </Typography>

      <CardTable items={byStreet} />
    </div>
  )
}
