import React, { FC } from 'react'
import { Typography, Grid, Link } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useAuth } from '@flint/core'

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { Path } from '@/types'
import { ReactComponent as AndroidStore } from '@/assets/images/android-arabic.svg'
import { ReactComponent as AppleStore } from '@/assets/images/apple-arabic.svg'
import { UserBlack as UserIcon } from '@/icons'
import MakanInfographic from '@/assets/images/makan-infographic.png'
import MakanLogo from '@/assets/images/makan-logo-full.png'
import MakanBgMask from '@/assets/images/makan-bg-mask.png'
import { useStyle } from './Home.style'

export const HomePage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { loggedIn } = useAuth()

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          lg={6}
          className={classes.aboutGrid}
          style={{
            backgroundImage: `url(${MakanBgMask})`,
          }}
        >
          <div className={classes.appLogoContainer}>
            <img src={MakanLogo} className={classes.appLogo} alt="makan logo" />
          </div>

          <Typography className={classes.whatIsMakan}>
            {t('what is makan platform')}
          </Typography>

          <Typography className={classes.whatIsMakanDescription}>
            {t('what is makan platform description')}
          </Typography>

          <div className={classes.subscribeContainer}>
            <Link href={Path.QUERIES} className={classes.subscriberPortal}>
              <UserIcon />
              <span className={classes.subscriberPortalText}>
                {t('subscriber portal')}
              </span>
            </Link>

            {!loggedIn && (
              <Link href={Path.QUERIES} className={classes.loginInButton}>
                <span className={classes.loginInButtonText}>{t('login')}</span>
                <ArrowBackIcon />
              </Link>
            )}
          </div>

          <div className={classes.contactUsContainer}>
            <Typography className={classes.contactUs}>
              {t('to subscribe contact us')}
            </Typography>

            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@gt.com.sa"
              className={classes.contactEmail}
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@gt.com.sa
            </a>
          </div>
        </Grid>

        <Grid item xs={12} lg={6} className={classes.detailsGrid}>
          <div className={classes.appInfographicContainer}>
            <img
              src={MakanInfographic}
              className={classes.appInfographic}
              alt="makan infographic"
            />
          </div>

          <Typography className={classes.description}>
            {t('makan platform description')}
          </Typography>

          <Typography className={classes.moreInfo}>
            {t('more info provided when requested')}
          </Typography>

          <div className={classes.headingAppStoreIcons}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Link
                  href="https://apps.apple.com/sa/app/makan-%D9%85%D9%83%D8%A7%D9%86/id1536383020"
                  target="_blank"
                >
                  <AppleStore width="155" />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://play.google.com/store/apps/details?id=ai.makan.app"
                  target="_blank"
                >
                  <AndroidStore width="170" />
                </Link>
              </Grid>
            </Grid>
          </div>

          <Typography className={classes.copyright}>
            {t('all rights reserved', { year: new Date().getFullYear() })}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
