import React, { FC, useState } from 'react'
import ClipboardJS from 'clipboard'
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Block as BlockIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons'
import { Modal } from '@/components/Modal'
import { useTranslation } from '@flint/locales'
import { useMutation } from '@flint/graphql'
import { UPDATE_APP, DISABLE_APP } from '@/graphql'
import { ApplicationSettings as ApplicationSettingsIcon } from '@/icons'
import { AppPageItemProps } from './AppPageItem.interface'
import { useStyle } from './AppPageItem.style'

export const AppPageItem: FC<AppPageItemProps> = ({ app, refetch }) => {
  const { name, key: apiKey, id } = app
  const classes = useStyle()
  const [appKey, setAppKey] = useState(apiKey)
  const [appName, setAppName] = useState(name)
  const [showAppKey, setShowAppKey] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [editApp, setEditApp] = useState(false)
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false)
  const [UpdateApp] = useMutation(UPDATE_APP)
  const [DisableApp] = useMutation(DISABLE_APP)
  const { t } = useTranslation()
  const copyTextToClipboard = () => {
    const button = document.createElement('button')
    // eslint-disable-next-line no-new
    new ClipboardJS(button, {
      text() {
        return appKey
      },
    })

    document.body.appendChild(button)
    setTimeout(() => {
      button.click()
      button.remove()
      // eslint-disable-next-line no-alert
      alert('The APP Key has been copied to clipboard')
    }, 50)
  }

  const onClickShowAppKey = () => {
    setShowAppKey(!showAppKey)
  }

  const onChangeAppKey = (e) => {
    setAppKey(e.currentTarget.value)
  }
  const onChangeAppName = (e) => {
    setAppName(e.currentTarget.value)
  }
  const handleModalClose = () => {
    setOpenModal(false)
  }
  const handleDeleteApp = () => {
    setConfirmButtonLoading(true)
    try {
      DisableApp({
        variables: {
          id,
        },
      }).then(() => {
        refetch()
      })
    } finally {
      setConfirmButtonLoading(false)
      handleModalClose()
    }
  }

  const handleEditApp = async () => {
    setConfirmButtonLoading(true)
    try {
      UpdateApp({
        variables: {
          name: appName,
          id,
        },
      }).then(() => {
        refetch()
      })
    } finally {
      setTimeout(() => {
        setConfirmButtonLoading(false)
        setEditApp(false)
      }, 2000)
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.appItem}>
        <div className={classes.appIcon}>
          <ApplicationSettingsIcon />
        </div>
        <div className={classes.appDetails}>
          <div className={classes.appTitleContainer}>
            {editApp ? (
              <input
                value={appName}
                placeholder="APP Name"
                onChange={onChangeAppName}
                className={classes.appKeyInput}
                type="text"
              />
            ) : (
              <Typography className={classes.appTitle}>{name}</Typography>
            )}

            {!editApp && (
              <div className={classes.appActions}>
                <Tooltip title="تعديل">
                  <EditIcon
                    className={classes.appActionIcon}
                    onClick={() => setEditApp(true)}
                  />
                </Tooltip>

                <Tooltip title="تعطيل">
                  <BlockIcon
                    className={classes.appActionIcon}
                    onClick={() => setOpenModal(true)}
                  />
                </Tooltip>
              </div>
            )}
          </div>

          <Typography className={classes.appSubTitle}>api key</Typography>

          <div className={classes.appKeyContainer}>
            <input
              value={appKey}
              placeholder="API Key"
              onChange={onChangeAppKey}
              className={classes.appKeyInput}
              type={showAppKey ? 'text' : 'password'}
            />

            <div className={classes.appKeyActions}>
              {showAppKey ? (
                <VisibilityOffIcon
                  onClick={onClickShowAppKey}
                  className={classes.appKeyAction}
                />
              ) : (
                <VisibilityIcon
                  onClick={onClickShowAppKey}
                  className={classes.appKeyAction}
                />
              )}
              <FileCopyIcon
                onClick={copyTextToClipboard}
                className={classes.appKeyAction}
              />
            </div>
          </div>
          {editApp && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="confirm register"
                  disableElevation
                  className={classes.confirmButton}
                  fullWidth
                  onClick={handleEditApp}
                >
                  {confirmButtonLoading ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    <span> {t('حفظ')}</span>
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  variant="outlined"
                  aria-label="confirm register"
                  disableElevation
                  className={classes.confirmButton}
                  fullWidth
                  onClick={() => setEditApp(false)}
                >
                  {t('الغاء ')}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      <Modal
        open={openModal}
        message="هل تريد تعطيل هذا التطبيق ؟"
        onClickCloseModal={handleModalClose}
        confirmButtonText="نعم"
        onClickConfirmButton={handleDeleteApp}
        confirmButtonLoading={confirmButtonLoading}
      />
    </div>
  )
}
