import React, { useState, useEffect } from 'react'
import {
  Table,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useTranslation } from '@flint/locales'
import { AppProgress } from '@/components/UI/AppProgress'
import useOrg from '@/hooks/useOrg'
import { useQuery } from '@flint/graphql'
import { GET_API_TRANSACTIONS } from '@/graphql/queries'
import { QueryLogsItem } from './QueryLogsItem'
import { useStyle } from './QueryLogs.style'

export const QueryLogs = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const [transactions, setTransactions] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const {
    activeOrg: { id: orgId },
  } = useOrg()

  const { data, loading } = useQuery(GET_API_TRANSACTIONS, {
    variables: {
      orgId,
      limit,
      offset,
    },
  })

  const onPageChange = (_, updatedPage) => {
    setPage(updatedPage)
  }

  const onRowsPerPageChange = (e) => {
    setPage(1)
    setLimit(parseInt(e.target.value, 10))
  }

  useEffect(() => {
    const updatedOffset = (page - 1) * limit

    setOffset(updatedOffset < 0 ? 0 : updatedOffset)
  }, [page, limit])

  useEffect(() => {
    if (data) {
      const { results, totalCount: total } = data.apiTransactions
      setTransactions(results)
      setTotalCount(Math.ceil(total / limit))
    }
  }, [data])

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle}>{t('the log')}</Typography>

      <TableContainer className={classes.tableContainer}>
        {loading && (
          <div className={classes.progressContainer}>
            <AppProgress active />
          </div>
        )}

        <Table aria-label="collapsible table" id="q-table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Response Time</TableCell>
              <TableCell align="right">Request Time</TableCell>
              <TableCell align="right">IP</TableCell>
              <TableCell align="right">Application / User</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <QueryLogsItem key={transaction.id} transaction={transaction} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.pagination}>
        <Pagination
          page={page}
          count={totalCount}
          onChange={onPageChange}
          size="medium"
          variant="outlined"
          color="primary"
          shape="rounded"
        />

        <Select
          value={limit}
          label={t('rows per page')}
          onChange={onRowsPerPageChange}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </div>
    </div>
  )
}
