import utils from '@/core/utils'
import { AxiosRequestConfig } from 'axios'

export const transformRequestParamsInterceptor = async (
  config: AxiosRequestConfig
) => {
  /**
   * Set Authorization header to request.
   *
   */
  const clonedConfig = config

  if (config.params && Object.keys(config.params)) {
    clonedConfig.params = utils.objDeepLoop(config.params, (key) =>
      utils.strToSnakeCase(key)
    )
  }

  return clonedConfig
}
