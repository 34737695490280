import React from 'react'
import { FieldProps } from '@rjsf/core'
import moment from 'moment'
import { Grid, Typography } from '@material-ui/core'
import { CardTable, CardTableItem } from '@flint/atoms'
import utils from '@/core/utils'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { useTranslation } from '@flint/locales'
import { useStyle } from './QueriesReviewGuiHistoricalTransactions.style'

const { numberFormat, isTrue } = utils

export const QueriesReviewGuiHistoricalTransactions = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData: data,
    uiSchema: { 'ui:sku': name },
  } = props
  const title = `api.${name}_title`

  const priceRate = (price, index) => {
    let value = 0
    const newPrice = price
    const original = index === 0 ? 0 : data[index - 1].price_per_m2
    let status: 'eq' | 'hi' | 'low' = 'eq'

    if (index !== 0) {
      if (newPrice > original) {
        value = ((newPrice - original) / original) * 100
        status = 'hi'
      } else if (newPrice < original) {
        value = ((original - newPrice) / original) * 100 * -1
        status = 'low'
      }
    }

    let displayedArrow = null
    if (status !== 'eq') {
      if (status === 'hi') {
        displayedArrow = <ArrowDropUpIcon htmlColor="green" />
      } else {
        displayedArrow = <ArrowDropDownIcon htmlColor="red" />
      }
    }

    let statusColor = 'black'
    if (status === 'low') {
      statusColor = 'red'
    } else if (status === 'hi') {
      statusColor = 'green'
    }
    // status === 'low' ? 'red' : status === 'hi' ? 'green' : 'black'

    return (
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid>{`${numberFormat(price)} ${t('SAR')}`}</Grid>
        <Grid>
          <div className={classes.ppmRate}>
            {index === 0 ? (
              '-'
            ) : (
              <>
                {displayedArrow}
                <span
                  style={{
                    direction: 'ltr',
                    color: statusColor,
                  }}
                >
                  {`${Math.round(value)}%`}
                </span>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    )
  }

  const cellItem = (content) =>
    new CardTableItem({
      content,
      cellProps: {
        style: {
          maxWidth: 50,
          textAlign: 'center',
        },
      },
    })

  const transactions = [
    [
      cellItem(''),
      t('transaction date'),
      t('price sar'),
      t('price pm sar'),
      t('type'),
      t('area'),
    ],
    ...data
      .sort((a, b) => {
        const firstEl = moment(a.transaction_date).unix()
        const secondEl = moment(b.transaction_date).unix()
        if (firstEl < secondEl) {
          return -1
        }
        if (firstEl === secondEl) {
          return 0
        }
        return 1
      })
      .map((transaction, index) => [
        cellItem(index + 1),
        moment(transaction.transaction_date).locale('en').format('DD/MM/YYYY'),
        `${numberFormat(transaction.price)} ${t('SAR')}`,
        priceRate(transaction.price_per_m2, index),
        [(transaction.moj_real_estate_use, transaction.moj_real_estate_type)]
          .filter(Boolean)
          .map((item) => t(`real_estate_types.${item.toLowerCase()}`))
          .join(' / '),
        `${numberFormat(transaction.area)} ${t('m2')}`,
      ]),
  ]

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      {!isTrue(data) ? (
        <div className={classes.emptyCard}>{t('no results')}</div>
      ) : (
        <CardTable items={transactions} />
      )}
    </div>
  )
}
