import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import { useStyles } from './ButtonsGroup.style'
import { ButtonsGroupProps, ButtonsGroupItem } from './ButtonsGroup.interface'

export const ButtonsGroup: FunctionComponent<ButtonsGroupProps> = ({
  items,
  active,
  onClick,
  sm = false,
}: ButtonsGroupProps) => {
  const classes = useStyles()

  // when an item has clicked - return a click handler method
  const buildClickHanlder =
    (item: ButtonsGroupItem) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(item.value, event)
      }
    }

  const buildButtonClasses = (item: ButtonsGroupItem) => ({
    root: clsx({
      [classes.active]: item.value === active,
    }),
  })

  const buttonGroupClasses = {
    root: classes.root,
    grouped: clsx({
      [classes.grouped]: true,
      [classes.small]: sm,
    }),
  }

  return (
    <ButtonGroup fullWidth={!sm} classes={buttonGroupClasses}>
      {items.map((item) => (
        <Button
          key={item.value}
          size={sm ? 'small' : 'large'}
          onClick={buildClickHanlder(item)}
          classes={buildButtonClasses(item)}
        >
          {item.text}
        </Button>
      ))}
    </ButtonGroup>
  )
}
