import React, { useState, useEffect, FC } from 'react'
import { useMap } from '@/hooks/useMap'
import { MapPin } from '@/icons'
import ReactDom from 'react-dom'
import GoogleMapReact from 'google-map-react'
import { IItem, MapProps } from './QueriesReviewGuiMap.interface'
import { useStyle } from './QueriesReviewGuiMap.style'

export const Map: FC<MapProps> = ({
  items,
  MapInfoWindow,
  infoWindowOffset,
  height,
  // mapTypeControl,
  updateFitBoundZoomLevelBy,
}) => {
  const classes = useStyle()
  const [markers, setMarkers] = useState([])
  const [, setActiveInfoWindow] = useState(null)

  const { mapProps, googleMap, googleMaps } = useMap({
    defaultZoom: 8,
    hasMyLocation: false,
    options: {
      fullscreenControl: false,
      gestureHandling: 'cooperative',
      mapTypeControl: true,
    },
  })

  const clearMapMarkers = () => {
    if (!markers.length) {
      return
    }

    setMarkers((prevMarkers) => {
      prevMarkers.forEach((marker) => {
        marker.setMap(null)
      })

      return []
    })
  }

  const buildInfoWindowWrapper = (item) => {
    const div = document.createElement('div')
    ReactDom.render(<MapInfoWindow item={item} />, div)

    return div
  }

  const buildInfoWindow = (item: IItem) => {
    const div = buildInfoWindowWrapper(item)
    const infowindow = new googleMaps.InfoWindow({
      content: div,
      pixelOffset: new googleMaps.Size(0, infoWindowOffset),
    })

    return infowindow
  }

  const insertMarkers = () => {
    clearMapMarkers()
    const markers: any[] = []
    const bounds = new googleMaps.LatLngBounds()

    items.forEach((item) => {
      const { latitude, longitude } = item.coordinates
      const marker = new googleMaps.Marker({
        map: googleMap,
        position: {
          lat: Number(latitude),
          lng: Number(longitude),
        },
        icon: MapPin,
      })

      if (MapInfoWindow) {
        const infowindow = buildInfoWindow(item, marker)

        marker.addListener('click', () => {
          setActiveInfoWindow((prevInfowindow) => {
            if (prevInfowindow) {
              prevInfowindow.close()
            }

            return infowindow
          })

          infowindow.open(googleMap, marker)
        })
      }

      markers.push(marker)
      bounds.extend(marker.getPosition())
    })

    setMarkers(markers)

    if (markers.length) {
      googleMap.fitBounds(bounds)
    }

    setTimeout(() => {
      googleMap.setZoom(googleMap.getZoom() + (updateFitBoundZoomLevelBy || 0))
    }, 0)
  }

  useEffect(() => {
    if (googleMap && googleMaps) {
      insertMarkers()
    }
  }, [googleMap, googleMaps, items])

  return (
    <div className={classes.root}>
      <GoogleMapReact {...mapProps} style={{ height }} />
    </div>
  )
}
