import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { CardTable } from '@flint/atoms'
import { useQuery } from '@flint/graphql'
import { GET_IPS } from '@/graphql/queries'
import { useQuota } from '@/hooks'
import { useStyle } from './IPAddresses.style'
import { IPAddressesPageAddItemNotesCell } from './IPAddressesPageAddItemNotesCell'
import { IPAddressesPageAdd } from './IPAddressesPageAdd'

export const IPAddresses = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const [IPsList, setIPsList] = useState([])
  const {
    quota: {
      organization: { id: orgId },
    },
  } = useQuota()

  const { data, refetch } = useQuery(GET_IPS, {
    variables: {
      orgId,
    },
  })
  const [ipFormInitialValues, setIpFormInitialValues] = useState({
    id: '',
    ip: '',
    notes: '',
  })
  useEffect(() => {
    if (data) {
      const ips = [
        [t('indexing'), t('ip address'), t('notes')],
        ...data.ips.map(({ id, ip, notes }) => [
          id,
          ip,
          <IPAddressesPageAddItemNotesCell
            text={notes || '-'}
            id={Number(id)}
            ip={ip}
            refetch={refetch}
            setIpFormInitialValues={setIpFormInitialValues}
          />,
        ]),
      ]

      setIPsList(ips)
    }
  }, [data])

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item md={7}>
          <Typography className={classes.listTitle}>
            {t('saved addresses')}
          </Typography>

          <CardTable items={IPsList} />
        </Grid>

        <Grid item md={5}>
          <IPAddressesPageAdd
            onSuccessCreate={refetch}
            ipFormInitialValues={ipFormInitialValues}
            setIpFormInitialValues={setIpFormInitialValues}
          />
        </Grid>
      </Grid>
    </div>
  )
}
