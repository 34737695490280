import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: theme.spacing(4),
  },
  addUserButton: {
    color: 'white',
    textDecoration: 'none',
  },
}))
