import React from 'react'
import { Layout } from '@/modules/Layout'
import { useTranslation } from '@flint/locales'

export const ApiPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      pageTitle={t('api integration')}
      pageSubTitle={t('api integration page subtitle')}
    >
      <div>Hello from API page</div>
    </Layout>
  )
}
