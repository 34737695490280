import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { useTranslation } from '@flint/locales'
import { Typography, CircularProgress } from '@material-ui/core'
import { useStyle } from './LoadingProgress.style'
import { LoadingProgressProps } from './LoadingProgress.interface'

export const LoadingProgress: FunctionComponent<LoadingProgressProps> = ({
  loading,
}) => {
  const classes = useStyle()
  const { t } = useTranslation()
  return (
    <div
      className={clsx(classes.loading, {
        [classes.hide]: !loading,
      })}
    >
      <CircularProgress />
      <Typography variant="h6">{t('please wait')}</Typography>
    </div>
  )
}
