import React, { FC, useEffect, useState } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useSearchParams } from 'react-router-dom'
import { useStyle } from './QueriesContentAPIFormRequiredServices.style'
import {
  ICategory,
  QueriesContentAPIFormRequiredServicesProps,
} from './QueriesContentAPIFormRequiredServices.interface'
import { QueriesContentAPIFormRequiredServicesCategory } from './QueriesContentAPIFormRequiredServicesCategory'

// check if api categories have any services
const hasServices = (v: ICategory[]) => {
  return v.reduce((prev, curr) => [...prev, ...curr.services], []).length
}

export const QueriesContentAPIFormRequiredServices: FC<
  QueriesContentAPIFormRequiredServicesProps
> = ({ services, categories, value, setValue, id, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const [selectAll, setSelectAll] = useState(false)

  const [params] = useSearchParams()

  useEffect(() => {
    if (params.get('service')) {
      services.forEach(({ sku }) => {
        if (sku === params.get('service')) setValue([...value, sku])
      })
    }
  }, [])

  const onClickSelectAll = (): void => {
    const skus = services.map(({ sku }) => sku)

    if (selectAll) {
      setValue([])
    } else {
      setValue(skus)
    }

    setSelectAll(!selectAll)
  }

  useEffect(() => {
    const checkIfAllServicesSelected = () => {
      setSelectAll(value.length === services.length)
    }

    checkIfAllServicesSelected()
  }, [value, services])

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          {t('required services')}*
        </Typography>

        <Button
          disabled={disabled}
          onClick={onClickSelectAll}
          className={classes.selectAllButton}
        >
          {t(selectAll ? 'cancel select all' : 'select all')}
        </Button>
      </div>

      {hasServices(categories) ? (
        <Grid container spacing={2} style={{ alignItems: 'self-start' }}>
          {categories.map((category) =>
            category?.services.length ? (
              <Grid item md={6} key={category.name}>
                <QueriesContentAPIFormRequiredServicesCategory
                  id={id}
                  value={value}
                  disabled={disabled}
                  setValue={setValue}
                  category={category}
                />
              </Grid>
            ) : null
          )}
        </Grid>
      ) : (
        <h3 className={classes.noServicesNotification}>
          لا يوجد خدمات متاحة لهذا الاستعلام
        </h3>
      )}
    </div>
  )
}
