import { MainButton } from '@/components/UI'
import { Grid, Typography, Link } from '@material-ui/core'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from '@flint/locales'
import { useDispatch } from 'react-redux'
import { useQuery } from '@flint/graphql'
import { GET_USERS } from '@/graphql'
import { UsersPageCard } from '@/modules/UsersPage/UsersPageCard'
import { AppProgress } from '@/components/UI/AppProgress'
import { Path } from '@/types'
import { useQuota } from '@/hooks'
import { useStyle } from './UsersContainer.style'

export const UsersPageContainer: FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const dispatch = useDispatch()
  const {
    quota: {
      organization: { id: orgId },
    },
  } = useQuota()
  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      orgId,
    },
  })
  const users = useMemo(() => (data && data.users) || [], [data])
  useEffect(() => {
    const payload = (
      <MainButton color="primary" variant="contained" size="large">
        <Link href="/users/add" className={classes.addUserButton}>
          {t('add a new user')}
        </Link>
      </MainButton>
    )
    dispatch({
      reducer: 'flint',
      type: 'toolbarAdditional',
      payload,
    })
    return () => {
      dispatch({
        reducer: 'flint',
        type: 'toolbarAdditional',
        payload: undefined,
      })
    }
  }, [])

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {t('registered users')}
      </Typography>

      <Grid container spacing={3}>
        <AppProgress
          active={loading}
          data={users}
          noData={{
            message: 'there are no users yet, add a new one',
            button: {
              content: t('add a new user'),
              onClick: Path.USERS_ADD,
            },
          }}
        >
          {users.map((user) => (
            <UsersPageCard {...user} key={user.id} />
          ))}
        </AppProgress>
      </Grid>
    </div>
  )
}
