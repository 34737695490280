import React from 'react'
import { Typography } from '@material-ui/core'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { Options as IOptions } from 'highcharts'
import { useTranslation } from '@flint/locales'
import { FieldProps } from '@rjsf/core'
import { defaultChartOptions } from '@/modules/tempSchemas'
import { LineChartI, Transaction } from './QueriesReviewGuiChart.interface'
import { useStyle } from './QueriesReviewGuiChart.style'

// const getCategoryValue = (v) => {
//   return `${new Date(v).getMonth() + 1} / ${new Date(v).getFullYear()}`
// }

const getCategoriesAndSeriesData = (d: Transaction[]) => {
  return d.reduce(
    (prev, curr) => ({
      categories: [...prev.categories, curr.x],
      series: [...prev.series, curr.y],
    }),
    { categories: [], series: [] }
  )
}

export const QueriesReviewGuiChart = (props: FieldProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  const {
    formData,
    uiSchema: { 'ui:sku': name, chartOptions },
  }: LineChartI = props
  const { categories, series } = getCategoriesAndSeriesData(formData[0] ?? [])
  const title = `api.${name}_title`

  const formatChartOptions = (options: IOptions) => {
    return {
      ...options,
      series: [{ data: series ?? [] }],
      xAxis: { ...options.xAxis, categories },
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t(title)}</Typography>

      <div className={classes.chart}>
        <HighchartsReact
          highcharts={Highcharts}
          options={formatChartOptions(chartOptions ?? defaultChartOptions)}
          isPureConfig
        />
      </div>
    </div>
  )
}
