import { getMappedReducers } from '@flint/utils'
import { quotaInitialState } from './quota'
import { queryInitialState } from './query'
import { setOrgState } from './org'
import { layoutReducer } from './layout'

export const plainReducers = getMappedReducers({
  prefix: '',
  reducers: {
    quota: quotaInitialState,
    query: queryInitialState,
    org: setOrgState,
    layout: layoutReducer,
  },
})
